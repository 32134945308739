import { motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { productsData } from "../data/ProductsData";
import { cartListAtom, filterCategoryAtom } from "../recoil/atoms/CartStateAtom";
import FilterSortOptions from "../utils/FilterSortOptions";
import { addToCartItem, handleQuantityChange } from "../utils/cartUtils";
import ProductCard from "./ProductCard";

const Products = () => {
  // State for filter and sort
  const [sortBy, setSortBy] = useState("relevance");
  const [filterCategory, setFilterCategory] = useRecoilState(filterCategoryAtom);
  const [cartList, setCartList] = useRecoilState(cartListAtom);

  useEffect(() => {
    return () => {
      setFilterCategory('all');
    };
  }, [setFilterCategory]);

  // Memoize sorted and filtered products to prevent unnecessary re-renders
  const sortedProducts = useMemo(() => {
    const sorted = [...productsData];
    if (sortBy === "price-asc") sorted.sort((a, b) => a.price - b.price);
    if (sortBy === "price-desc") sorted.sort((a, b) => b.price - a.price);
    return sorted;
  }, [sortBy]);

  const filteredProducts = useMemo(
    () =>
      filterCategory === "all"
        ? sortedProducts
        : sortedProducts.filter(
            (product) => product.category.toLowerCase() === filterCategory.toLowerCase()
          ),
    [filterCategory, sortedProducts]
  );

  return (
    <div className="bg-gradient-to-b from-amber-50 to-amber-100/30 py-20">
      <div className="container mx-auto px-6 md:px-8 lg:px-12">
        <motion.div
          className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center gap-6 mb-16"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-stone-800 font-serif relative">
            Our Products
            <span className="absolute -bottom-2 left-0 w-1/3 h-1 bg-amber-300/60 rounded-full"></span>
          </h2>
          <FilterSortOptions
            sortBy={sortBy}
            setSortBy={setSortBy}
            filterCategory={filterCategory}
            setFilterCategory={setFilterCategory}
          />
        </motion.div>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          {filteredProducts.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              cartItem={cartList.find((item) => item.id === product.id)}
              handleQuantityChange={(change) =>
                handleQuantityChange(product, change, setCartList)
              }
              addToCart={() => addToCartItem(product, setCartList)}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Products;
