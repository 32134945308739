import React from "react";
import styled from "styled-components";

const AddToCart = () => {
  return (
    <StyledWrapper>
      <button className="cartBtn" aria-label="Add to cart">
        <span className="button-content">
          <svg
            className="cart"
            fill="currentColor"
            viewBox="0 0 576 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
          </svg>
          <span className="button-text">ADD TO CART</span>
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
          className="product"
          fill="currentColor"
        >
          <path d="M211.8 0c7.8 0 14.3 5.7 16.7 13.2C240.8 51.9 277.1 80 320 80s79.2-28.1 91.5-66.8C413.9 5.7 420.4 0 428.2 0h12.6c22.5 0 44.2 7.9 61.5 22.3L628.5 127.4c6.6 5.5 10.7 13.5 11.4 22.1s-2.1 17.1-7.8 23.6l-56 64c-11.4 13.1-31.2 14.6-44.6 3.5L480 197.7V448c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64V197.7l-51.5 42.9c-13.3 11.1-33.1 9.6-44.6-3.5l-56-64c-5.7-6.5-8.5-15-7.8-23.6s4.8-16.6 11.4-22.1L137.7 22.3C155 7.9 176.7 0 199.2 0h12.6z" />
        </svg>
      </button>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .cartBtn {
    /* Base Styles */
    position: relative;
    width: 100%;
    max-width: min(200px, 90vw);
    min-height: clamp(40px, 6vh, 50px);
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 9999px;
    background: black;
    color: white;
    cursor: pointer;
    overflow: hidden;
    isolation: isolate;

    /* Modern Shadow */
    box-shadow: 0 4px 6px -1px rgba(0, 128, 0, 0.1),
      0 2px 4px -1px rgba(0, 128, 0, 0.06),
      inset 0 1px 0 rgba(255, 255, 255, 0.1);

    /* Smooth Transitions */
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    /* Hover Effects */
    &:hover {
      transform: translateY(-2px) scale(1.01);
      box-shadow: 0 12px 20px -5px rgba(0, 128, 0, 0.15),
        0 4px 6px -2px rgba(0, 128, 0, 0.05),
        inset 0 1px 0 rgba(255, 255, 255, 0.15);
      background: linear-gradient(
        135deg,
        hsl(112, 52%, 57%) 0%,
        hsl(122, 41%, 51%) 100%
      );
    }

    /* Active State */
    &:active {
      transform: translateY(0) scale(0.98);
      box-shadow: 0 2px 4px -1px rgba(0, 128, 0, 0.1),
        0 1px 2px -1px rgba(0, 128, 0, 0.06);
    }

    /* Focus State */
    &:focus-visible {
      outline: 2px solid hsl(122, 39%, 49%);
      outline-offset: 2px;
    }

    /* Button Content Layout */
    .button-content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: clamp(0.5rem, 2vw, 0.75rem);
      z-index: 2;
    }

    /* Icon Styles */
    .cart {
      height: clamp(16px, 2.5vw, 20px);
      width: auto;
      flex-shrink: 0;
      transition: transform 0.3s ease;
    }

    /* Text Styles */
    .button-text {
      font-size: clamp(0.75rem, 2vw, 0.875rem);
      font-weight: 600;
      letter-spacing: 0.025em;
      white-space: nowrap;
    }

    /* Product Icon Animation */
    .product {
      position: absolute;
      width: clamp(12px, 2vw, 15px);
      height: auto;
      left: 20%;
      bottom: 20%;
      opacity: 0;
      color: rgba(255, 255, 255, 0.8);
      pointer-events: none;
    }

    &:hover .product {
      animation: float-up 1.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }

    &:hover .cart {
      transform: scale(1.1);
    }
  }

  /* Floating Animation */
  @keyframes float-up {
    0% {
      transform: translateY(20px) rotate(-10deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translateY(-20px) rotate(10deg);
      opacity: 0;
    }
  }

  /* Touch Device Optimizations */
  @media (hover: none) {
    .cartBtn {
      &:active {
        transform: scale(0.98);
        background: linear-gradient(
          135deg,
          hsl(112, 52%, 57%) 0%,
          hsl(122, 41%, 51%) 100%
        );
      }
    }
  }

  /* Responsive Adjustments */
  @media (max-width: 480px) {
    .cartBtn {
      padding: 0.5rem 1rem;

      .button-content {
        gap: 0.5rem;
      }

      .button-text {
        font-size: clamp(0.6875rem, 1.5vw, 0.75rem);
      }
    }
  }

  /* High Contrast Mode Support */
  @media (forced-colors: active) {
    .cartBtn {
      border: 2px solid ButtonText;
      background: ButtonFace;
      color: ButtonText;
    }
  }

  /* Reduced Motion Preferences */
  @media (prefers-reduced-motion: reduce) {
    .cartBtn {
      transition: none;

      &:hover .product {
        animation: none;
      }

      &:hover .cart {
        transform: none;
      }
    }
  }
`;

export default AddToCart;
