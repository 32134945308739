import { React, useState } from "react";

const EmailSection = ({ userData, setUserData, toggleSection }) => {
  const [newEmail, setNewEmail] = useState(userData.email);

  const handleEmailUpdate = () => {
    // Implement email update logic
    setUserData((prev) => ({ ...prev, email: newEmail }));
    toggleSection(null);
  };

  return (
    <div className="p-4 border-t">
      <div className="flex items-center gap-3 mb-4">
        <input
          type="email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          className="flex-1 p-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 outline-none"
        />
      </div>
      <div className="flex justify-end space-x-2">
        <button
          onClick={() => toggleSection(null)}
          className="px-3 py-2 text-gray-600 hover:bg-gray-50 rounded-lg"
        >
          Cancel
        </button>
        <button
          onClick={handleEmailUpdate}
          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EmailSection;
