export const productsData = [
  {
    id: 1,
    name: "Fresh Whole Chicken",
    description:
      "Farm-raised, antibiotic-free whole chicken perfect for roasting or grilling.",
    quantity: "1Kg",
    price: 320,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513962/ffw/Fresh%20Whole%20Chicken/qb4lpilbhfl6eymoxbgk.jpg",
    tags: ["🍗 Poultry", "🏡 Farm-fresh", "🍽️ Versatile"],
    category: "Chicken",
    nutritionalInfo: {
      protein: "31g",
      fat: "3.6g",
      calories: "165 per 100g",
    },
  },
  {
    id: 2,
    name: "Farm Fresh Eggs (Pack of 12)",
    description:
      "Large, grade A eggs from free-range hens. Ideal for breakfast or baking.",
    quantity: "1 Dozen",
    price: 120,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513887/ffw/Farm%20Fresh%20Eggs%20%28Pack%20of%2012%29/qpibhx9rw11ysgpehnza.jpg",
    tags: ["🥚 Eggs", "🍳 Breakfast", "🧁 Baking"],
    category: "Dairy & Eggs",
    nutritionalInfo: {
      protein: "6g",
      fat: "5g",
      calories: "70 per egg",
    },
  },
  {
    id: 3,
    name: "Farm Fresh Eggs (Pack of 6)",
    description:
      "Half dozen large, grade A eggs from free-range hens. Perfect for small households.",
    quantity: "1/2 Dozen",
    price: 65,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513902/ffw/Farm%20Fresh%20Eggs%20%28Pack%20of%206%29/lqnsiyev6dfrzqcjuojx.jpg",
    tags: ["🥚 Eggs", "🍳 Breakfast", "🧁 Baking"],
    category: "Dairy & Eggs",
    nutritionalInfo: {
      protein: "6g",
      fat: "5g",
      calories: "70 per egg",
    },
  },
  {
    id: 4,
    name: "Chicken Small Cut Pieces",
    description:
      "Convenient, pre-cut chicken pieces perfect for curries and stir-fries.",
    quantity: "1Kg",
    price: 320,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513830/ffw/Chicken%20Small%20Cut%20Pieces/t89pkwzy1hrsixccyihw.jpg",
    tags: ["🍗 Poultry", "🍛 Curry", "🥘 Stir-fry"],
    category: "Chicken",
    nutritionalInfo: {
      protein: "31g",
      fat: "3.6g",
      calories: "165 per 100g",
    },
  },
  {
    id: 5,
    name: "Premium Chicken Boneless",
    description:
      "Tender, boneless chicken breast fillets. Ideal for quick and healthy meals.",
    quantity: "1Kg",
    price: 450,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733514081/ffw/Premium%20Chicken%20Boneless/qvpxilohn2wlhlc9cv3x.jpg",
    tags: ["🍗 Poultry", "💪 High Protein", "🥗 Healthy"],
    category: "Chicken",
    nutritionalInfo: {
      protein: "31g",
      fat: "3.6g",
      calories: "165 per 100g",
    },
  },
  {
    id: 6,
    name: "Chicken Boneless Family Pack",
    description:
      "Value pack of tender, boneless chicken breast fillets. Great for meal prep and large families.",
    quantity: "2Kg",
    price: 900,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513763/ffw/Chicken%20Boneless%20Family%20Pack/k5umfrkzfktpvzlii84s.jpg",
    tags: ["🍗 Poultry", "👨‍👩‍👧‍👦 Family Size", "🍱 Meal Prep"],
    category: "Chicken",
    nutritionalInfo: {
      protein: "31g",
      fat: "3.6g",
      calories: "165 per 100g",
    },
  },
  {
    id: 7,
    name: "Biryani Cut Chicken Pieces",
    description:
      "Specially cut chicken pieces perfect for making delicious biryani.",
    quantity: "1Kg",
    price: 350,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513726/ffw/Biryani%20Cut%20Chicken%20Pieces/wtf695n427sz2tkrnjpp.jpg",
    tags: ["🍗 Poultry", "🍚 Biryani", "🍲 Indian Cuisine"],
    category: "Chicken",
    nutritionalInfo: {
      protein: "31g",
      fat: "3.6g",
      calories: "165 per 100g",
    },
  },
  {
    id: 8,
    name: "Fresh Chicken Liver",
    description:
      "Nutrient-rich chicken liver, perfect for pâtés or traditional recipes.",
    quantity: "1/2 Kg",
    price: 250,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513933/ffw/Fresh%20Chicken%20Liver/sbsodivkiolzs1r2ia36.jpg",
    tags: ["🍗 Poultry", "🫀 Organ Meat", "🍲 Nutrient-dense"],
    category: "Chicken",
    nutritionalInfo: {
      protein: "26g",
      fat: "4.8g",
      calories: "167 per 100g",
    },
  },
  {
    id: 9,
    name: "Organic Country Eggs (Pack of 12)",
    description:
      "Premium, organic eggs from free-range country hens. Rich in flavor and nutrients.",
    quantity: "1 Dozen",
    price: 180,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733514048/ffw/Organic%20Country%20Eggs%20%28Pack%20of%2012%29/umjho98ivfc1d22liism.jpg",
    tags: ["🥚 Eggs", "🌿 Organic", "🐓 Free-range"],
    category: "Dairy & Eggs",
    nutritionalInfo: {
      protein: "6g",
      fat: "5g",
      calories: "70 per egg",
    },
  },
  {
    id: 10,
    name: "Organic Country Eggs (Pack of 6)",
    description:
      "Half dozen premium, organic eggs from free-range country hens. Perfect for small households.",
    quantity: "1/2 Dozen",
    price: 90,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513995/ffw/Organic%20Country%20Eggs%20%28Pack%20of%206%29/ad5sse7xiqflkxodpif3.jpg",
    tags: ["🥚 Eggs", "🌿 Organic", "🐓 Free-range"],
    category: "Dairy & Eggs",
    nutritionalInfo: {
      protein: "6g",
      fat: "5g",
      calories: "70 per egg",
    },
  },
  {
    id: 11,
    name: "Chicken Drumsticks",
    description:
      "Juicy chicken drumsticks, perfect for grilling or baking. A family favorite.",
    quantity: "1Kg",
    price: 280,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513798/ffw/Chicken%20Drumsticks/pz17zl7q8dmp0jkpk9cv.jpg",
    tags: ["🍗 Poultry", "🔥 Grill", "👨‍👩‍👧‍👦 Family Meal"],
    category: "Chicken",
    nutritionalInfo: {
      protein: "28g",
      fat: "7.8g",
      calories: "185 per 100g",
    },
  },
  {
    id: 12,
    name: "Chicken Wings",
    description:
      "Flavorful chicken wings, great for parties or as a snack. Easy to season and cook.",
    quantity: "1Kg",
    price: 300,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513870/ffw/Chicken%20Wings/rmy2lphmh5lehjgesxm3.jpg",
    tags: ["🍗 Poultry", "🎉 Party Food", "🍽️ Appetizer"],
    category: "Chicken",
    nutritionalInfo: {
      protein: "25g",
      fat: "9g",
      calories: "203 per 100g",
    },
  },
  {
    id: 13,
    name: "Chicken Sausages",
    description:
      "Delicious chicken sausages, perfect for grilling or adding to pasta dishes.",
    quantity: "500g",
    price: 350,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513813/ffw/Chicken%20Sausages/h7mlmc1lu0bogsbfbqwk.jpg",
    tags: ["🍗 Poultry", "🌭 Sausages", "🍝 Pasta"],
    category: "Chicken",
    nutritionalInfo: {
      protein: "20g",
      fat: "15g",
      calories: "250 per 100g",
    },
  },
  {
    id: 14,
    name: "Chicken Thighs",
    description:
      "Tender and juicy chicken thighs, perfect for slow cooking or grilling.",
    quantity: "1Kg",
    price: 400,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513847/ffw/Chicken%20Thighs/jsd88wofj7oychgsezsf.jpg",
    tags: ["🍗 Poultry", "🔥 Grill", "🍲 Slow Cook"],
    category: "Chicken",
    nutritionalInfo: {
      protein: "26g",
      fat: "10g",
      calories: "209 per 100g",
    },
  },
  {
    id: 15,
    name: "Chicken Breast Strips",
    description:
      "Marinated chicken breast strips, ready for stir-frying or grilling.",
    quantity: "500g",
    price: 450,
    image: "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513781/ffw/Chicken%20Breast%20Strips/rmgub0c2mdyjod0gt7zp.jpg",
    tags: ["🍗 Poultry", "🥡 Quick Meal", "🥗 Healthy"],
    category: "Chicken",
    nutritionalInfo: {
      protein: "30g",
      fat: "3g",
      calories: "165 per 100g",
    },
  },
];
