import { X } from "lucide-react";
import React, { useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { OffersData } from "../data/OffersData";
import { cartListAtom, discountAtom } from "../recoil/atoms/CartStateAtom";
import { enableOffer } from "../utils/validateOfferUtil";

const DiscountModal = ({ isOpen, onClose, onSelectOffer }) => {
  const cartListItems = useRecoilValue(cartListAtom);
  const setDiscountAtom = useSetRecoilState(discountAtom);

  const filteredOffers = useMemo(() => {
    return OffersData.filter((offer) => offer.flag);
  }, []);

  const handleOfferSelect = (offer) => {
    const isEligible = enableOffer(offer.promoCode, cartListItems);

    if (isEligible) {
      setDiscountAtom((prevState) => ({
        ...prevState,
        offerCode: offer.promoCode,
      }));
      const selectedOfferDetails = {
        promoCode: offer.promoCode,
        title: offer.title,
        description: offer.description,
      };

      onClose();

      setTimeout(() => {
        onSelectOffer(selectedOfferDetails);
      }, 100);
    } else {
      console.warn("Offer not eligible for selection");
    }
  };

  return (
    <>
      {/* Backdrop */}
      <div
        className={`fixed inset-0 bg-black/30 transition-opacity z-40
          ${isOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
        onClick={onClose}
      />

      {/* Sidebar */}
      <div
        className={`fixed right-0 top-0 h-full w-full max-w-md bg-white shadow-xl 
          transform transition-transform duration-300 ease-in-out z-50
          ${isOpen ? "translate-x-0" : "translate-x-full"}`}
      >
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-xl font-semibold">Exclusive Offers</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {/* Offers List */}
        <div className="p-4 space-y-4 overflow-y-auto h-[calc(100vh-4rem)]">
          {filteredOffers.length > 0 ? (
            filteredOffers.map((offer) => {
              const isEligible = enableOffer(
                offer.promoCode,
                cartListItems,
                setDiscountAtom
              );
              return (
                <div
                  key={offer.id}
                  className={`p-4 border rounded-lg transition-all duration-200
                  ${
                    isEligible
                      ? "border-indigo-400 cursor-pointer group hover:shadow-md"
                      : "opacity-50 cursor-not-allowed"
                  }`}
                  onClick={() => handleOfferSelect(offer)}
                >
                  <div className="flex justify-between items-start mb-2">
                    <h3 className="font-semibold text-lg group-hover:text-indigo-600 transition-colors">
                      {offer.title}
                    </h3>
                  </div>
                  <p className="text-sm text-gray-600">{offer.description}</p>
                  <div className="flex items-center space-x-2 mt-2">
                    <span className="text-xs text-gray-500">Promo code:</span>
                    <code className="px-2 py-1 rounded text-sm font-mono bg-gray-100 text-gray-800">
                      {offer.promoCode}
                    </code>
                    {["B1G1FRESH", "EGGSTRA4YOU", "DOUBLEDELIGHT"].includes(
                      offer.promoCode
                    ) && (
                      <span className="text-xs text-indigo-600 ml-2">
                        *Chicken items only
                      </span>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center text-gray-500 py-10">
              No offers available
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DiscountModal;
