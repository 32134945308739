import { atom } from "recoil";

export const cartListAtom = atom({
  key: "cartList",
  default: [],
});

export const discountAtom = atom({
  key: "discount",
  default: {
    discount: 0,
    offerCode: null,
    offerDescription: "",
  },
});

export const filterCategoryAtom = atom({
  key: "filterCategory",
  default: "all",
});
