import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import Banner1 from "../../gallery/Banner1.png";
import Banner2 from "../../gallery/Banner2.png";

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const images = [Banner1, Banner2];
  const slideDuration = 5000; // 5 seconds per slide

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images.length]);

  const handlePrev = useCallback(() => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  }, [images.length]);

  useEffect(() => {
    if (!isPaused) {
      const timer = setInterval(() => {
        handleNext();
      }, slideDuration);

      return () => clearInterval(timer);
    }
  }, [isPaused, handleNext, slideDuration]);

  // Progress bar animation duration should match slide duration
  const progressVariants = {
    initial: { width: 0 },
    animate: {
      width: "100%",
      transition: { duration: slideDuration / 1000, ease: "linear" },
    },
  };

  return (
    <div
      className="relative overflow-hidden mx-auto rounded-lg"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, x: 300 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -300 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className="w-full h-[150px] sm:h-[200px] md:h-[400px] lg:h-[600px] rounded-lg overflow-hidden"
        >
          <img
            src={images[currentIndex]}
            alt={`Banner ${currentIndex + 1}`}
            className="w-full h-full object-cover"
          />

          {/* Progress Bar */}
          {!isPaused && (
            <motion.div
              className="absolute bottom-0 left-0 h-1 bg-white/50"
              variants={progressVariants}
              initial="initial"
              animate="animate"
              key={`progress-${currentIndex}`}
            />
          )}
        </motion.div>
      </AnimatePresence>

      {/* Navigation Buttons */}
      <button
        onClick={() => {
          handlePrev();
          setIsPaused(true);
        }}
        className="absolute top-1/2 left-2 sm:left-4 transform -translate-y-1/2 bg-white/80 backdrop-blur-sm rounded-full p-1 sm:p-2 md:p-3 hover:bg-[#FFC0CB] duration-200 shadow-md"
        aria-label="Previous Slide"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 sm:h-5 sm:w-5 md:h-6 md:w-6 lg:h-8 lg:w-8 text-gray-800"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>

      <button
        onClick={() => {
          handleNext();
          setIsPaused(true);
        }}
        className="absolute top-1/2 right-2 sm:right-4 transform -translate-y-1/2 bg-white/80 backdrop-blur-sm rounded-full p-1 sm:p-2 md:p-3 hover:bg-[#FFC0CB] duration-200 shadow-md"
        aria-label="Next Slide"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 sm:h-5 sm:w-5 md:h-6 md:w-6 lg:h-8 lg:w-8 text-gray-800"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>

      {/* Dots Indicator */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => {
              setCurrentIndex(index);
              setIsPaused(true);
            }}
            className={`transition-all duration-300 ${
              index === currentIndex
                ? "w-8 h-2 bg-white"
                : "w-2 h-2 bg-white/50 hover:bg-white/80"
            } rounded-full`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>

      {/* Play/Pause Button */}
      {/* <button
        onClick={() => setIsPaused(!isPaused)}
        className="absolute bottom-4 right-4 bg-white/80 backdrop-blur-sm rounded-full p-2 hover:bg-[#FFC0CB] duration-200 shadow-md"
        aria-label={isPaused ? "Play slideshow" : "Pause slideshow"}
      >
        {isPaused ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 md:h-5 md:w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 md:h-5 md:w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 9v6m4-6v6"
            />
          </svg>
        )}
      </button> */}
    </div>
  );
};

export default Slider;
