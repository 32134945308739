import { API_ENDPOINTS } from "../config/endpoints";

export const paymentInitiate = async (totalAmount) => {
  if (!totalAmount || isNaN(totalAmount) || totalAmount <= 0) {
    throw new Error("Invalid total amount for payment initiation.");
  }

  try {
    const response = await fetch(API_ENDPOINTS.INITIATE_PAYMENT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        amount: parseFloat(totalAmount),
      }),
    });
    if (!response.ok) {
      const errorDetails = await response.json();
      throw new Error(errorDetails.message || "Failed to create order");
    }

    return response.text();
  } catch (error) {
    throw new Error("Payment initiation failed:", error);
  }
};

export const savePaymentDetails = async (
  order_id,
  payment_id,
  signature,
  amount,
  status
) => {
  //   if (!paymentData || typeof paymentData !== "object") {
  //     throw new Error("Invalid payment data.");
  //   }
  try {
    const response = await fetch(API_ENDPOINTS.SAVE_PAYMENT_DETAILS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        razorpayOrderId: order_id,
        razorpayPaymentId: payment_id,
        razorpaySignature: signature,
        amount: amount,
        status: status,
      }),
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      throw new Error(errorDetails.message || "Payment verification failed");
    }

    return response.text();
  } catch (error) {
    console.error("Failed to save payment details:", error);
    throw new Error("Unable to save payment details at the moment.");
  }
};
