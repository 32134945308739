import { motion } from "framer-motion";
import React, { useState } from "react";
import { signUp } from "../api/auth";
import Validations from "../utils/Validations";

const SignUpForm = ({ signUpFormOpen, onClose }) => {
  const [formData, setFormData] = useState({
    userProfile: {
      firstName: "",
      lastName: "",
      birthDate: "",
      phoneNumber: "",
    },
    email: "",
    password: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      if (name === "password" || name === "email") {
        return {
          ...prev,
          [name]: value,
        };
      }
      return {
        ...prev,
        userProfile: {
          ...prev.userProfile,
          [name]: value,
        },
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValid) {
      setError("Please fix all validation errors before submitting.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const data = await signUp(formData);
      localStorage.setItem("token", data);
      localStorage.setItem("auth", "true");
      window.dispatchEvent(new Event("storage"));
      onClose();
      setFormData({
        userProfile: {
          firstName: "",
          lastName: "",
          birthDate: "",
          phoneNumber: "",
        },
        email: "",
        password: "",
      });
      window.location.reload();
    } catch (error) {
      setError("Registration failed. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  if (!signUpFormOpen) return null;

  return (
    <div className="w-full">
      <div className="mb-8">
        <h2 className="text-3xl font-bold text-primary-500 text-center">
          Join Our Family!
        </h2>
        <p className="text-gray-500 text-center mt-2">Sign up now</p>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      <motion.form
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        onSubmit={handleSubmit}
        className="w-full"
      >
        <Validations
          formData={formData}
          onChange={handleChange}
          setIsValid={setIsValid}
        />

        <div className="mt-6">
          <button
            type="submit"
            disabled={loading || !isValid}
            className={`w-full bg-primary hover:bg-primary-600 text-white font-bold py-2 px-4 rounded-lg transition-colors duration-300 ${
              loading || !isValid ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading ? "Signing Up..." : "Sign Up"}
          </button>
        </div>
      </motion.form>
    </div>
  );
};

export default SignUpForm;
