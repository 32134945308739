import { motion } from "framer-motion";
import React, { useState } from "react";
import { FaBox, FaShoppingBag, FaTruck } from "react-icons/fa";

// Mock order data (replace with actual backend data)
const mockOrders = [
  {
    id: "ORD001",
    date: "2023-12-01",
    total: 129.99,
    status: "Delivered",
    items: [
      { name: "Wireless Headphones", quantity: 1, price: 79.99 },
      { name: "Phone Case", quantity: 2, price: 25.0 },
    ],
  },
  {
    id: "ORD002",
    date: "2023-11-15",
    total: 199.5,
    status: "Shipped",
    items: [{ name: "Smart Watch", quantity: 1, price: 199.5 }],
  },
  {
    id: "ORD003",
    date: "2023-10-20",
    total: 85.75,
    status: "Delivered",
    items: [{ name: "Bluetooth Speaker", quantity: 1, price: 85.75 }],
  },
];

const Orders = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  // Status color mapping
  const getStatusColor = (status) => {
    switch (status) {
      case "Delivered":
        return "text-green-600";
      case "Shipped":
        return "text-blue-600";
      case "Processing":
        return "text-yellow-600";
      default:
        return "text-gray-600";
    }
  };

  // Status icon mapping
  const getStatusIcon = (status) => {
    switch (status) {
      case "Delivered":
        return FaBox;
      case "Shipped":
        return FaTruck;
      case "Processing":
        return FaShoppingBag;
      default:
        return FaShoppingBag;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-6 text-gray-900">Order History</h2>

      {mockOrders.length === 0 ? (
        <div className="text-center text-gray-500 py-8">
          No orders found. Start shopping to see your order history!
        </div>
      ) : (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {mockOrders.map((order) => {
            const StatusIcon = getStatusIcon(order.status);

            return (
              <motion.div
                key={order.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className={`
                  bg-white shadow-lg rounded-lg p-6 
                  hover:shadow-xl transition-all duration-300
                  cursor-pointer border-l-4 
                  ${getStatusColor(order.status)}`}
                onClick={() => setSelectedOrder(order)}
              >
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-semibold">Order #{order.id}</h3>
                  <StatusIcon className="text-2xl" />
                </div>
                <div className="space-y-2">
                  <p>Date: {order.date}</p>
                  <p>Total: ${order.total.toFixed(2)}</p>
                  <p className={`font-bold ${getStatusColor(order.status)}`}>
                    Status: {order.status}
                  </p>
                </div>
              </motion.div>
            );
          })}
        </div>
      )}

      {/* Optional: Order Details Modal */}
      {selectedOrder && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => setSelectedOrder(null)}
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            className="bg-white rounded-lg p-8 max-w-md w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-2xl font-bold mb-4">Order Details</h3>
            <div className="space-y-2">
              <p>Order ID: {selectedOrder.id}</p>
              <p>Date: {selectedOrder.date}</p>
              <p>Total: ${selectedOrder.total.toFixed(2)}</p>
              <p
                className={`font-bold ${getStatusColor(selectedOrder.status)}`}
              >
                Status: {selectedOrder.status}
              </p>

              <h4 className="text-lg font-semibold mt-4">Items:</h4>
              {selectedOrder.items.map((item, index) => (
                <div key={index} className="flex justify-between border-b py-2">
                  <span>{item.name}</span>
                  <span>
                    {item.quantity} x ${item.price.toFixed(2)}
                  </span>
                </div>
              ))}
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default Orders;
