export const loadRazorpayScript = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => resolve(true);
    script.onerror = () => reject(new Error("Razorpay SDK failed to load"));
    document.body.appendChild(script);
  });
};

export const createRazorpayOptions = ({
  key,
  amount,
  currency,
  orderId,
  prefill,
  theme,
  handler,
  modal,
}) => ({
  key,
  amount,
  currency,
  order_id: orderId,
  prefill,
  theme,
  handler,
  modal,
});
