import React from 'react';
import { motion } from 'framer-motion';

export const Confetti = () => {
    const generateConfetti = () => {
      return Array.from({ length: 50 }, (_, index) => ({
        id: index,
        x: Math.random() * window.innerWidth,
        y: -50,
        delay: Math.random() * 2,
        color: ['#FFD700', '#FFA500', '#FF6347', '#4169E1', '#32CD32'][Math.floor(Math.random() * 5)]
      }));
    };
  
    const confettiPieces = generateConfetti();
  
    return (
      <div className="fixed inset-0 pointer-events-none z-50">
        {confettiPieces.map((piece) => (
          <motion.div
            key={piece.id}
            className="absolute w-2 h-2 rounded-full"
            style={{ backgroundColor: piece.color }}
            initial={{ 
              x: piece.x, 
              y: piece.y,
              opacity: 1 
            }}
            animate={{ 
              y: window.innerHeight + 100, 
              x: piece.x + (Math.random() - 0.5) * 200,
              rotate: 360,
              opacity: 0
            }}
            transition={{
              duration: 3,
              delay: piece.delay,
              ease: "easeOut"
            }}
          />
        ))}
      </div>
    );
  };
  