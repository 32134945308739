import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import {
  FaEnvelope,
  FaHome,
  FaInfoCircle,
  FaProductHunt,
  FaUserCircle,
} from "react-icons/fa";
import { MdMenu } from "react-icons/md";
import { SiCodefresh } from "react-icons/si";
import { Link } from "react-router-dom";
import LoginForm from "../Forms/LoginForm";
import Cart from "../cart/Cart";

const Navbar = () => {
  const headerData = [
    { id: 1, title: "Home", link: "/" },
    { id: 2, title: "Products", link: "/products" },
    { id: 3, title: "About", link: "/about" },
    { id: 4, title: "Contact", link: "/contact" },
  ];

  const iconMap = {
    Home: <FaHome />,
    Products: <FaProductHunt />,
    About: <FaInfoCircle />,
    Contact: <FaEnvelope />,
  };

  // State management
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [open, setOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const menuRef = useRef(null);

  // Check authentication status on mount and when localStorage changes
  useEffect(() => {
    const checkAuth = () => {
      const auth = localStorage.getItem("auth");
      setIsAuthenticated(auth === "true");
    };

    checkAuth();

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Listen for storage changes
    window.addEventListener("storage", checkAuth);
    return () => {
      window.removeEventListener("storage", checkAuth);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("auth");
    setIsAuthenticated(false);
    setShowProfileMenu(false);
  };

  return (
    <>
      <nav className="bg-white shadow-sm sticky top-0 z-50 border-b border-gray-100">
        <div className="container max-w-7xl mx-auto flex justify-between items-center py-4 px-4 lg:px-8">
          {/* Mobile Hamburger */}
          <button
            className="lg:hidden p-2 hover:bg-gray-50 rounded-lg transition-colors duration-200"
            onClick={() => setOpen(!open)}
            aria-label="Menu"
            aria-expanded={open}
            aria-controls="mobile-menu"
          >
            <MdMenu className="w-6 h-6 text-gray-700" />
          </button>

          {/* Logo */}
          <Link to="/" className="flex items-center gap-2 group">
            <SiCodefresh className="w-6 h-6 lg:w-7 lg:h-7 text-indigo-600 group-hover:text-indigo-700 transition-colors duration-200" />
            <div className="text-xl lg:text-2xl font-bold tracking-tight">
              <span className="text-gray-900">Fresh</span>
              <span className="text-indigo-600">Foods</span>
            </div>
          </Link>

          {/* Desktop Menu */}
          <div className="hidden lg:flex flex-1 justify-center">
            <ul className="flex gap-1">
              {headerData.map((item) => (
                <li key={item.id}>
                  <Link
                    to={item.link}
                    className="inline-block py-2 px-4 text-base font-medium text-gray-600 hover:text-indigo-600 rounded-lg hover:bg-gray-50 transition-colors duration-200"
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Icons Section */}
          <div className="flex items-center gap-2 sm:gap-3 lg:gap-4">
            {/* Search Button */}
            <button
              className="p-2 text-gray-600 hover:text-indigo-600 hover:bg-gray-50 rounded-lg transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              aria-label="Search"
            >
              <CiSearch className="w-6 h-6" />
            </button>

            {/* Cart Component */}
            <div className="p-2">
              <Cart />
            </div>

            {/* Authentication Section */}
            {isAuthenticated ? (
              <div className="relative">
                <button
                  ref={menuRef}
                  onClick={() => setShowProfileMenu(!showProfileMenu)}
                  className="flex items-center gap-2 p-2 text-gray-600 hover:text-indigo-600 hover:bg-gray-50 rounded-lg transition-all duration-200"
                >
                  <FaUserCircle className="w-6 h-6" />
                  <span className="hidden sm:inline text-sm font-medium">
                    Account
                  </span>
                </button>

                {/* Profile Dropdown */}
                {showProfileMenu && (
                  <div
                    ref={menuRef}
                    className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50"
                  >
                    <Link
                      to="/account/profile"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => setShowProfileMenu(false)}
                    >
                      Profile
                    </Link>
                    <Link
                      to="/account/orders"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => setShowProfileMenu(false)}
                    >
                      Orders
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-50"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <button
                onClick={() => setFormOpen(true)}
                className="hidden sm:flex items-center px-4 py-2 text-sm font-medium text-indigo-600 bg-white border border-indigo-600 rounded-lg hover:bg-indigo-600 hover:text-white transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Login
              </button>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {open && (
            <>
              {/* Backdrop */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black/40 backdrop-blur-sm z-40 lg:hidden"
                onClick={() => setOpen(false)}
              />

              {/* Menu */}
              <motion.div
                initial={{ x: "-100%" }}
                animate={{ x: 0 }}
                exit={{ x: "-100%" }}
                transition={{ type: "spring", damping: 25, stiffness: 200 }}
                className="fixed left-0 top-0 h-full w-64 bg-white shadow-xl z-50 lg:hidden"
              >
                <div className="flex flex-col h-full">
                  {/* Mobile Menu Header */}
                  <div className="flex items-center justify-between p-4 border-b border-gray-100">
                    <div className="flex items-center gap-2">
                      <SiCodefresh className="w-6 h-6 text-indigo-600" />
                      <span className="font-semibold text-gray-900">Menu</span>
                    </div>
                    <button
                      onClick={() => setOpen(false)}
                      className="p-2 hover:bg-gray-50 rounded-lg transition-colors duration-200"
                    >
                      <svg
                        className="w-5 h-5 text-gray-500"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* Mobile Menu Items */}
                  <div className="flex-1 overflow-y-auto py-4">
                    <ul className="space-y-1 px-3">
                      {headerData.map((item) => (
                        <li key={item.id}>
                          <Link
                            to={item.link}
                            className="block py-2 px-4 text-base font-medium text-gray-600 hover:text-indigo-600 rounded-lg hover:bg-gray-50 transition-colors duration-200"
                            onClick={() => setOpen(false)}
                          >
                            <div className="flex items-center gap-2">
                              <p className="text-indigo-600">
                                {iconMap[item.title]}
                              </p>
                              <p>{item.title}</p>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Mobile Menu Footer */}
                  <div className="border-t border-gray-100 p-4">
                    {isAuthenticated ? (
                      <div className="space-y-2">
                        <Link
                          to="/profile"
                          className="block w-full px-4 py-2 text-sm font-medium text-gray-600 hover:text-indigo-600 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200"
                          onClick={() => setOpen(false)}
                        >
                          Profile
                        </Link>
                        <button
                          onClick={() => {
                            handleLogout();
                            setOpen(false);
                          }}
                          className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition-colors duration-200"
                        >
                          Logout
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => {
                          setFormOpen(true);
                          setOpen(false);
                        }}
                        className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition-colors duration-200"
                      >
                        Login
                      </button>
                    )}
                  </div>
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </nav>

      {/* Login Form */}
      <LoginForm formOpen={formOpen} onClose={() => setFormOpen(false)} />
    </>
  );
};

export default Navbar;
