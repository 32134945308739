import { motion } from "framer-motion";
import React, { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import DeleteButton from "../ResuableComponents/DeleteButton";
import SideBarQunatityChange from "../helper/SideBarQunatityChange";
import { cartListAtom, discountAtom } from "../recoil/atoms/CartStateAtom";
import { removeFromCart } from "../utils/cartUtils";
import { applicablePromocodes } from "../utils/validateOfferUtil";

const CartItem = ({ item }) => {
  const setCartList = useSetRecoilState(cartListAtom);

  const [discountState, setDiscountState] = useRecoilState(discountAtom);
  const currentCartItems = useRecoilValue(cartListAtom);

  const handleDelete = useCallback(() => {
    removeFromCart(item.id, setCartList);
  }, [item.id, setCartList]);

  useEffect(() => {
    const allApplicableOffers = applicablePromocodes(currentCartItems);
    const isValid = allApplicableOffers.includes(discountState.offerCode);

    if (!isValid) {
      // If the promo code is no longer valid, reset the discount state
      setDiscountState((prevState) => ({
        ...prevState,
        offerCode: "",
        discount: 0,
      }));
    }
  }, [currentCartItems, discountState.offerCode, setDiscountState]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="flex items-start gap-4 p-4 border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow bg-white"
    >
      {/* Product Image */}
      <div className="w-20 h-20 bg-gray-50 rounded-md overflow-hidden flex-shrink-0">
        <img
          src={item.image || "/api/placeholder/80/80"}
          alt={item.name}
          className="w-full h-full object-cover"
        />
      </div>

      {/* Product Details */}
      <div className="flex-1 min-w-0">
        <div className="flex items-start justify-between">
          <h3 className="font-medium text-lg truncate">{item.name}</h3>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleDelete}
            className="cursor-pointer"
          >
            <DeleteButton />
          </motion.div>
        </div>
        <div className="flex items-center justify-between mt-3">
          <div className="flex items-center gap-3">
            <SideBarQunatityChange item={item} setCartList={setCartList} />
          </div>
          <p className="text-emerald-600 font-bold text-lg">
            ₹{item.totalPrice}
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default CartItem;
