import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { PiShoppingCartThin } from "react-icons/pi";
import { useRecoilValue, useSetRecoilState } from "recoil";
import PaymentFailure from "../ResuableComponents/Modals/PaymentFailure";
import PaymentSuccess from "../ResuableComponents/Modals/PaymentSuccess";
import { cartListAtom } from "../recoil/atoms/CartStateAtom";
import { handleCheckOut } from "../utils/paymentGateway";
import { loadRazorpayScript } from "../utils/razorpayHelper";
import CartList from "./CartList";
import Summary from "./Summary";

const Cart = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const cartListItems = useRecoilValue(cartListAtom);
  const setCartItems = useSetRecoilState(cartListAtom);
  const [summaryData, setSummaryData] = useState({
    totalMRP: 0,
    shippingFee: 0,
    tax: 0,
    totalAmount: 0,
  });

  useEffect(() => {
    loadRazorpayScript();

    const calculatedTotalPrice = cartListItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );

    const totalMRP = calculatedTotalPrice;

    const tax = (totalMRP * 0.05).toFixed(2);
    const totalAmount = (totalMRP + parseFloat(tax)).toFixed(2);
    setSummaryData({
      totalMRP,
      shippingFee: 0,
      tax,
      totalAmount,
    });
  }, [cartListItems]);

  const handlePaymentSuccess = () => {
    setCartItems([]); // Clear the cart
    setPaymentStatus("success");
  };

  const handlePaymentFailure = () => {
    setPaymentStatus("failure");
  };

  const handleCloseModal = () => {
    setPaymentStatus(null);
  };

  const handleCloseCart = () => {
    setIsOpen(false);
    setPaymentStatus(null);
  };

  const checkCartState = cartListItems.length === 0;

  return (
    <div className="relative">
      {/* Cart Icon */}
      <div
        className="relative text-2xl cursor-pointer group"
        onClick={() => setIsOpen(true)}
      >
        <div className="p-2 rounded-full transition-all duration-300 group-hover:bg-gray-100 group-hover:scale-105">
          <PiShoppingCartThin className="w-6 h-6 text-gray-800" />
        </div>
        <AnimatePresence>
          {cartListItems.length > 0 && (
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              className="absolute -top-2 -right-2 bg-red-600 text-white text-xs font-medium rounded-full w-5 h-5 flex items-center justify-center"
            >
              {cartListItems.length}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Cart Sidebar */}
      <AnimatePresence>
        {isOpen && (
          <>
            {/* Backdrop with blur*/}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/40 backdrop-blur-sm z-40"
              onClick={handleCloseCart}
            />

            {/* Sidebar */}
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ type: "spring", damping: 25, stiffness: 200 }}
              className="fixed right-0 top-0 h-full w-full max-w-md bg-white shadow-xl z-50 flex flex-col sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3"
            >
              {/* Header */}
              <div className="border-b border-gray-200 bg-white z-10 p-4">
                <div className="flex items-center justify-between">
                  <h2 className="text-xl font-semibold text-gray-800">
                    Shopping Cart
                    {cartListItems.length > 0 && (
                      <span className="text-sm text-gray-600 font-medium px-4">
                        {"("}
                        {cartListItems.length}{" "}
                        {cartListItems.length === 1 ? "item" : "items"}
                        {")"}
                      </span>
                    )}
                  </h2>
                  <button
                    onClick={handleCloseCart}
                    className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  >
                    <svg
                      className="w-5 h-5 text-gray-500"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Content */}
              <div className="flex-1 flex flex-col overflow-hidden overflow-y-auto">
                {cartListItems.length === 0 ? (
                  <div className="flex-1 flex flex-col items-center justify-center p-8 text-gray-500">
                    <PiShoppingCartThin className="w-24 h-24 mb-6 text-gray-300" />
                    <p className="text-xl font-medium mb-2">
                      Your cart is empty
                    </p>
                    <p className="text-sm text-gray-400">
                      Add items to get started
                    </p>
                  </div>
                ) : (
                  <>
                    {/* Scrollable Cart Items */}
                    <div className="flex-1 ">
                      <div className="p-4 space-y-4">
                        <CartList items={cartListItems} />
                        <Summary summaryData={summaryData} />
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* Payment Status Modals */}
              <AnimatePresence>
                {paymentStatus === "success" && (
                  <PaymentSuccess onClose={handleCloseModal} />
                )}
                {paymentStatus === "failure" && (
                  <PaymentFailure onClose={handleCloseModal} />
                )}
              </AnimatePresence>

              {/* Fixed Checkout Button */}
              <div className="p-4 bg-white border-t border-gray-200">
                <button
                  disabled={checkCartState}
                  className="w-full bg-indigo-600 text-white py-3 sm:py-4 rounded-lg font-semibold hover:bg-indigo-700 transition-all duration-200 shadow-md hover:shadow-lg active:scale-[0.99] disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={() => {
                    handleCheckOut(
                      summaryData,
                      handlePaymentSuccess,
                      handlePaymentFailure
                    )()
                      .then(handlePaymentSuccess)
                      .catch(handlePaymentFailure);
                  }}
                >
                  Proceed to Checkout
                </button>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Cart;
