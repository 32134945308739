import { motion } from "framer-motion";
import React from "react";
import AddToCart from "../helper/AddToCart";

const ProductCard = ({
  product,
  cartItem,
  handleQuantityChange,
  addToCart,
}) => (
  <motion.div
    className="bg-white rounded-2xl shadow-sm hover:shadow-lg overflow-hidden flex flex-col justify-between border border-stone-100 transition-shadow duration-300"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.6, ease: "easeOut" }}
    viewport={{ once: true }}
  >
    <motion.div
      className="relative h-72 overflow-hidden bg-stone-50"
      whileHover={{
        scale: 1.02,
        transition: { duration: 0.4, ease: "easeOut" },
      }}
    >
      <img
        src={product.image}
        alt={product.name}
        className="w-full h-full object-cover transform transition-all duration-700 hover:scale-105"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent"></div>
      <h3 className="absolute bottom-6 right-6 text-2xl font-serif font-bold text-white">
        {product.name}
      </h3>
    </motion.div>

    <div className="p-6 flex-grow">
      <p className="text-stone-600 leading-relaxed">{product.description}</p>
      <div className="mt-4 flex flex-wrap gap-2">
        {product.tags.map((tag, i) => (
          <motion.span
            key={i}
            className="bg-stone-100 text-stone-600 px-4 py-1.5 rounded-full text-sm font-medium"
            whileHover={{
              scale: 1.05,
              backgroundColor: "#f3f4f6",
              transition: { duration: 0.2, ease: "easeOut" },
            }}
          >
            {tag}
          </motion.span>
        ))}
      </div>
    </div>

    <div className="p-6 pt-2 flex justify-between items-center border-t border-stone-100">
      <p className="font-bold text-stone-800 font-serif">
        ₹{product.price.toLocaleString()} / {product.quantity}
      </p>
      {cartItem ? (
        <div className="flex items-center gap-3">
          <motion.button
            onClick={() => handleQuantityChange(-1)}
            className="w-8 h-8 flex items-center justify-center bg-stone-100 hover:bg-stone-200 rounded-full text-stone-600 transition-colors duration-200"
            whileTap={{ scale: 0.95 }}
          >
            -
          </motion.button>
          <span className="w-8 text-center font-medium text-stone-700">
            {cartItem.quantity}
          </span>
          <motion.button
            onClick={() => handleQuantityChange(1)}
            className="w-8 h-8 flex items-center justify-center bg-stone-100 hover:bg-stone-200 rounded-full text-stone-600 transition-colors duration-200"
            whileTap={{ scale: 0.95 }}
          >
            +
          </motion.button>
        </div>
      ) : (
        <motion.button
          className="rounded-full p-2 bg-stone-50 hover:bg-stone-100 transition-colors duration-300 ease-in-out"
          onClick={addToCart}
          whileHover={{
            scale: 1.05,
            transition: { duration: 0.3, ease: "easeOut" },
          }}
          whileTap={{
            scale: 0.95,
            transition: { duration: 0.1, ease: "easeOut" },
          }}
          aria-label="Add to cart"
        >
          <AddToCart />
        </motion.button>
      )}
    </div>
  </motion.div>
);

export default ProductCard;
