import { paymentInitiate, savePaymentDetails } from "../api/payment";
import { createRazorpayOptions, loadRazorpayScript } from "./razorpayHelper";

export const handleCheckOut =
  (summaryData, onSuccess, onFailure) => async () => {
    try {
      if (!summaryData || !summaryData.totalAmount) {
        throw new Error("Invalid summary data.");
      }

      const razorpayLoaded = await loadRazorpayScript();
      if (!razorpayLoaded) {
        throw new Error("Razorpay SDK failed to load");
      }

      const orderId = await paymentInitiate(summaryData.totalAmount);

      // Setup Razorpay options
      const options = createRazorpayOptions({
        key: "rzp_test_AD9FK8LcKyejNo",
        amount: parseFloat(summaryData.totalAmount) * 100,
        currency: "INR",
        orderId,
        prefill: {
          name: "Customer Name",
          email: "customer@example.com",
          contact: "9999999999",
        },
        theme: {
          color: "#4F46E5",
        },
        handler: async (response) => {
          try {
            // Save payment details
            await savePaymentDetails({
              razorpayOrderId: response.razorpay_order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpaySignature: response.razorpay_signature,
              amount: parseFloat(summaryData.totalAmount),
              status: "SUCCESS",
            });

            if (onSuccess) onSuccess();
          } catch (error) {
            console.error("Payment verification failed:", error);
            if (onFailure) onFailure(error);
          }
        },
        modal: {
          ondismiss: () => {
            console.log("Payment modal closed");
            if (onFailure) onFailure(new Error("Payment modal closed"));
          },
        },
      });

      // Open Razorpay payment modal
      const razorpayInstance = new window.Razorpay(options);
      razorpayInstance.open();
      setTimeout(() => {
        if (razorpayInstance) {
          razorpayInstance.close();
          console.error("Payment modal timed out");
          if (onFailure) onFailure(new Error("Payment modal timed out."));
        }
      }, 300000);
    } catch (error) {
      console.error("Checkout failed:", error);
      if (onFailure) {
        onFailure(error);
      }
    }
  };
