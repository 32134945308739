import React, { useState } from "react";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";

const SavedCards = ({ userData }) => {
  const [card, setCard] = useState(userData.cards);
  const [newCard, setNewCard] = useState({
    name: "",
    cardNumber: "",
    type: "",
    expiryDate: "",
  });

  const handlAddCard = () => {
    if (
      newCard.name &&
      newCard.cardNumber &&
      newCard.type &&
      newCard.expiryDate
    ) {
      setCard([
        ...card,
        {
          id: card.length + 1,
          ...newCard,
        },
      ]);
      setNewCard({ name: "", cardNumber: "", type: "", expiryDate: "" });
    }
  };

  const handleDeleteCard = (id) => {
    setCard(card.filter((card) => card.id !== id));
  };

  return (
    <div className="p-4 border-t space-y-4">
      {card.map((ucard) => (
        <div
          key={ucard.id}
          className="flex justify-between items-center p-3 bg-gray-50 rounded-lg"
        >
          <div>
            <p className="font-medium">{ucard.cardNumber}</p>
            <p className="text-sm text-gray-600">
              {ucard.name}, {ucard.expiryDate}, {ucard.type}
            </p>
          </div>
          <div className="flex space-x-2">
            <button
              className="text-indigo-600 hover:text-indigo-700"
              onClick={() => {
                /* Edit logic */
              }}
            >
              <FaEdit />
            </button>
            <button
              className="text-red-500 hover:text-red-600"
              onClick={() => handleDeleteCard(ucard.id)}
            >
              <FaTrash />
            </button>
          </div>
        </div>
      ))}

      {/* New Card Form */}
      <div className="space-y-3">
        <input
          type="text"
          placeholder="Card Number"
          value={newCard.cardNumber}
          onChange={(e) =>
            setNewCard((prev) => ({ ...prev, cardNumber: e.target.value }))
          }
          className="w-full p-2 border rounded-lg"
        />
        <div className="grid grid-cols-3 gap-2">
          <input
            type="text"
            placeholder="Name"
            value={newCard.name}
            onChange={(e) =>
              setNewCard((prev) => ({ ...prev, name: e.target.value }))
            }
            className="p-2 border rounded-lg"
          />
          <input
            type="text"
            placeholder="Type"
            value={newCard.type}
            onChange={(e) =>
              setNewCard((prev) => ({ ...prev, type: e.target.value }))
            }
            className="p-2 border rounded-lg"
          />
          <input
            type="text"
            placeholder="Expiry Date"
            value={newCard.expiryDate}
            onChange={(e) =>
              setNewCard((prev) => ({ ...prev, expiryDate: e.target.value }))
            }
            className="p-2 border rounded-lg"
          />
        </div>
        <button
          onClick={handlAddCard}
          className="w-full flex items-center justify-center gap-2 p-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          <FaPlus /> Add New Card
        </button>
      </div>
    </div>
  );
};

export default SavedCards;
