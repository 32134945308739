import { Minus, Plus } from "lucide-react";
import React from "react";
import { handleQuantityChange } from "../utils/cartUtils";

const SideBarQunatityChange = ({ item, setCartList }) => {
  return (
    <>
      {/* Actions and Price Container */}
      <div>
        {/* Quantity Controls with elegant design */}
        <div className="flex items-center bg-gray-100 rounded-full">
          <button
            className="p-1.5 text-gray-600 hover:text-emerald-600 
                           disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Decrease Quantity"
            onClick={() => handleQuantityChange(item, -1, setCartList)}
          >
            <Minus size={16} />
          </button>
          <span className="px-3 text-sm font-medium text-gray-800">
            {item.quantity}
          </span>
          <button
            className="p-1.5 text-gray-600 hover:text-emerald-600 
                           disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Increase Quantity"
            onClick={() => handleQuantityChange(item, 1, setCartList)}
          >
            <Plus size={16} />
          </button>
        </div>
      </div>
    </>
  );
};

export default SideBarQunatityChange;
