import { motion } from "framer-motion";
import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { discountAtom } from "../recoil/atoms/CartStateAtom";

// Define free item mappings
const FREE_ITEM_MAP = {
  B1G1FRESH: {
    name: "1 Kg Chicken",
    image:
      "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513962/ffw/Fresh%20Whole%20Chicken/ri4imwvokptjrw6hcqpq.jpg",
    id: "free-chicken-1kg",
    totalPrice: 320,
    quantity: 1,
    promoText: "Cluck-tastic Deal! Free Chicken Just for You! 🐔",
  },
  DOUBLEDELIGHT: {
    name: "2 Kg Chicken",
    image:
      "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513962/ffw/Fresh%20Whole%20Chicken/ri4imwvokptjrw6hcqpq.jpg",
    totalPrice: 600,
    id: "free-chicken-2kg",
    quantity: 1,
    promoText: "Double the Delight, Double the Chicken! 🎉",
  },
  EGGSTRA4YOU: {
    name: "4 Single Eggs",
    image:
      "https://res.cloudinary.com/djv3mrzk2/image/upload/v1733513901/ffw/Farm%20Fresh%20Eggs%20%28Pack%20of%206%29/f1dvtbpmr1uh9toahhs0.jpg",
    totalPrice: 65,
    id: "free-eggs-4",
    quantity: 4,
    promoText: "Egg-cellent Surprise! Free Eggs Are Here! 🥚",
  },
};

const FreeItem = () => {
  const discountState = useRecoilValue(discountAtom);

  const freeItem = useMemo(() => {
    if (discountState.offerCode && FREE_ITEM_MAP[discountState.offerCode]) {
      return FREE_ITEM_MAP[discountState.offerCode];
    }
    return null;
  }, [discountState.offerCode]);

  if (!freeItem) return null;

  // Animation variants for "FREE" text
  const freeTextVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: {
      scale: [0, 1.5, 1],
      opacity: [0, 1],
      color: ["#FF0000", "#FFD700", "#FF4500"],
      textShadow: "0px 0px 8px rgba(255, 215, 0, 0.8)",
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  // Background "blast" effect
  const blastEffectVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: {
      scale: [0, 1.5, 0],
      opacity: [0.8, 0.2, 0],
      backgroundColor: ["#FF4500", "#FFD700", "#FF4500"],
      borderRadius: ["50%", "50%", "50%"],
      transition: {
        duration: 1,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="relative flex items-start gap-4 p-4 border border-gray-200 rounded-lg shadow-sm hover:shadow-lg transition-shadow bg-gradient-to-r from-yellow-50 via-white to-yellow-100"
    >
      {/* Background Blast Effect */}
      <motion.div
        className="absolute inset-0 z-0"
        variants={blastEffectVariants}
        initial="initial"
        animate="animate"
      />

      {/* Product Image */}
      <div className="relative z-10 w-20 h-20 bg-gray-50 rounded-md overflow-hidden flex-shrink-0 shadow-lg">
        <img
          src={freeItem.image}
          alt={freeItem.name}
          className="w-full h-full object-cover"
        />
      </div>

      {/* Product Details */}
      <div className="relative z-10 flex-1 min-w-0">
        <div className="flex items-start justify-between">
          <h3 className="font-medium text-lg truncate flex items-center gap-2">
            {freeItem.name}
            <motion.span
              variants={freeTextVariants}
              initial="initial"
              animate="animate"
              className="text-red-500 ml-2 text-sm font-bold uppercase px-2 py-1 bg-yellow-100 rounded-md shadow-md tracking-wider"
            >
              FREE 🎉
            </motion.span>
          </h3>
        </div>

        <div className="flex items-center justify-between mt-3">
          <div className="flex items-center gap-3">
            <div className="text-gray-500">Quantity: {freeItem.quantity}</div>
          </div>

          <div className="flex flex-col items-end">
            <motion.p
              initial={{ opacity: 1 }}
              animate={{
                textDecoration: "line-through",
                color: "red",
                opacity: 0.5,
              }}
              className="text-gray-400 line-through text-sm"
            >
              ₹{freeItem.totalPrice}
            </motion.p>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: 0.2 },
              }}
              className="text-emerald-600 font-bold text-lg"
            >
              ₹0
            </motion.p>
          </div>
        </div>
        {/* Promo Text */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.4 },
          }}
          className="mt-2 text-sm text-yellow-600 font-semibold italic"
        >
          {freeItem.promoText}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default FreeItem;
