import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import { FaClipboardList, FaUser } from "react-icons/fa";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Orders from "./orders/Orders";
import ProfilePage from "./profile/Profile";

const Account = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Redirect to default tab on mount if no specific tab is in the route
  useEffect(() => {
    if (location.pathname === "/account") {
      navigate("/account/profile");
    }
  }, [location.pathname, navigate]);

  const tabs = [
    {
      id: "profile",
      label: "My Profile",
      icon: FaUser,
      path: "/account/profile",
    },
    {
      id: "orders",
      label: "My Orders",
      icon: FaClipboardList,
      path: "/account/orders",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Tab Buttons */}
      <div className="flex mb-6 bg-gray-100 rounded-lg pg-1">
        {tabs.map((tab) => (
          <NavLink
            key={tab.id}
            to={tab.path}
            className={({ isActive }) =>
              `flex items-center justify-center w-full py-3 rounded-lg transition-all duration-300 ${
                isActive
                  ? "bg-blue-500 text-white shadow-lg"
                  : "text-gray-600 hover:bg-gray-200"
              }`
            }
          >
            <tab.icon className="mr-2" />
            {tab.label}
          </NavLink>
        ))}
      </div>

      {/* Content Area with Framer Motion Animation */}
      <AnimatePresence mode="wait">
        <Routes>
          <Route
            path="/profile"
            element={
              <motion.div
                key="profile"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <ProfilePage />
              </motion.div>
            }
          />
          <Route
            path="/orders"
            element={
              <motion.div
                key="orders"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <Orders />
              </motion.div>
            }
          />
        </Routes>
      </AnimatePresence>
    </div>
  );
};

export default Account;
