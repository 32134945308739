import { AnimatePresence } from "framer-motion";
import React from "react";
import { useRecoilValue } from "recoil";
import { discountAtom } from "../recoil/atoms/CartStateAtom";
import CartItem from "./CartItem";
import FreeItem from "./FreeItem";

const CartList = ({ items }) => {
  const discountState = useRecoilValue(discountAtom);
  return (
    <div className="bg-white rounded-xl shadow-sm p-2 space-y-3">
      <AnimatePresence>
        {discountState.offerCode && <FreeItem />}

        {items.map((item) => (
          <CartItem key={item.id} item={item} />
        ))}
      </AnimatePresence>
    </div>
  );
};

export default CartList;
