import { motion } from "framer-motion";
import React from "react";

const FilterSortOptions = ({
  sortBy,
  setSortBy,
  filterCategory,
  setFilterCategory,
}) => {
  const selectClassName = `
    px-4 py-3 
    bg-white/80 backdrop-blur-sm
    border border-stone-200 
    rounded-xl
    text-stone-600
    shadow-sm
    transition-all duration-200
    hover:border-amber-200
    focus:outline-none focus:ring-2 focus:ring-amber-200 focus:border-amber-300
    w-full sm:w-auto
    appearance-none
    cursor-pointer
    font-medium
    pr-12
  `;

  return (
    <motion.div
      className="flex flex-row gap-4 w-full sm:w-auto"
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className="relative group">
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          className={selectClassName}
          aria-label="Sort by"
        >
          <option value="relevance">Sort by Relevance</option>
          <option value="price-asc">Price: Low to High</option>
          <option value="price-desc">Price: High to Low</option>
        </select>
        <div className="absolute inset-0 rounded-xl bg-amber-200/20 opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none"></div>
      </div>

      <div className="relative group">
        <select
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
          className={selectClassName}
          aria-label="Filter by category"
        >
          <option value="all">All Categories</option>
          <option value="Chicken">Chicken</option>
          <option value="Dairy & Eggs">Dairy & Eggs</option>
        </select>
        <div className="absolute inset-0 rounded-xl bg-amber-200/20 opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none"></div>
      </div>
    </motion.div>
  );
};

export default FilterSortOptions;
