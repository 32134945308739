import { React, useState } from "react";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";

const AddressesSection = ({ userData }) => {
  const [addresses, setAddresses] = useState(userData.addresses);
  const [newAddress, setNewAddress] = useState({
    street: "",
    city: "",
    state: "",
    zip: "",
  });

  const handleAddAddress = () => {
    if (
      newAddress.street &&
      newAddress.city &&
      newAddress.state &&
      newAddress.zip
    ) {
      setAddresses([
        ...addresses,
        {
          id: addresses.length + 1,
          ...newAddress,
        },
      ]);
      setNewAddress({ street: "", city: "", state: "", zip: "" });
    }
  };

  const handleDeleteAddress = (id) => {
    setAddresses(addresses.filter((addr) => addr.id !== id));
  };

  return (
    <div className="p-4 border-t space-y-4">
      {addresses.map((address) => (
        <div
          key={address.id}
          className="flex justify-between items-center p-3 bg-gray-50 rounded-lg"
        >
          <div>
            <p className="font-medium">{address.street}</p>
            <p className="text-sm text-gray-600">
              {address.city}, {address.state} {address.zip}
            </p>
          </div>
          <div className="flex space-x-2">
            <button
              className="text-indigo-600 hover:text-indigo-700"
              onClick={() => {
                /* Edit logic */
              }}
            >
              <FaEdit />
            </button>
            <button
              className="text-red-500 hover:text-red-600"
              onClick={() => handleDeleteAddress(address.id)}
            >
              <FaTrash />
            </button>
          </div>
        </div>
      ))}

      {/* New Address Form */}
      <div className="space-y-3">
        <input
          type="text"
          placeholder="Street Address"
          value={newAddress.street}
          onChange={(e) =>
            setNewAddress((prev) => ({ ...prev, street: e.target.value }))
          }
          className="w-full p-2 border rounded-lg"
        />
        <div className="grid grid-cols-3 gap-2">
          <input
            type="text"
            placeholder="City"
            value={newAddress.city}
            onChange={(e) =>
              setNewAddress((prev) => ({ ...prev, city: e.target.value }))
            }
            className="p-2 border rounded-lg"
          />
          <input
            type="text"
            placeholder="State"
            value={newAddress.state}
            onChange={(e) =>
              setNewAddress((prev) => ({ ...prev, state: e.target.value }))
            }
            className="p-2 border rounded-lg"
          />
          <input
            type="text"
            placeholder="Zip Code"
            value={newAddress.zip}
            onChange={(e) =>
              setNewAddress((prev) => ({ ...prev, zip: e.target.value }))
            }
            className="p-2 border rounded-lg"
          />
        </div>
        <button
          onClick={handleAddAddress}
          className="w-full flex items-center justify-center gap-2 p-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          <FaPlus /> Add New Address
        </button>
      </div>
    </div>
  );
};

export default AddressesSection;
