// ExpandableSection.js
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { FaEdit } from "react-icons/fa";

const ExpandableSection = ({
  title,
  icon: Icon,
  section,
  children,
  onEdit,
  expandedSection,
  toggleSection,
}) => {
  const sectionVariants = {
    closed: { height: 0, opacity: 0, overflow: "hidden" },
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div className="bg-white rounded-lg shadow-sm mb-4">
      <button
        onClick={() => toggleSection(section)}
        className="w-full flex justify-between items-center p-4 hover:bg-gray-50 transition-colors"
      >
        <div className="flex items-center gap-3">
          <Icon className="text-indigo-600 w-5 h-5" />
          <span className="font-medium text-gray-800">{title}</span>
        </div>
        {onEdit && expandedSection !== section && (
          <FaEdit
            className="text-gray-500 hover:text-indigo-600 transition-colors"
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          />
        )}
      </button>

      <AnimatePresence>
        {expandedSection === section && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={sectionVariants}
            className="overflow-hidden"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ExpandableSection;
