import { motion } from "framer-motion";
import { CheckCircle, X } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import { Confetti } from "../../utils/Confetti";

const PaymentSuccess = ({ onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm">
      <Confetti />
      <motion.div
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
        className="bg-white p-8 rounded-xl shadow-2xl text-center max-w-md w-full"
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <X size={24} />
        </button>

        {/* Checkmark Icon */}
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 300,
            delay: 0.2,
          }}
        >
          <CheckCircle
            className="mx-auto text-green-500 mb-4"
            size={80}
            strokeWidth={1.5}
          />
        </motion.div>
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Payment Successful!
        </h2>
        <p className="text-gray-600 mb-6">
          Your transaction has been completed successfully. Thank you for your
          purchase!
        </p>
        <Link to="/">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-green-500 text-white px-6 py-3 rounded-lg hover:bg-green-600 transition-colors"
          >
            Continue Shopping
          </motion.button>
        </Link>
      </motion.div>
    </div>
  );
};
export default PaymentSuccess;
