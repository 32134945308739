import React, { useEffect, useState } from "react";

const Validations = ({ formData, onChange, setIsValid }) => {
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });

  // Validation rules
  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
        if (!value.trim()) return "First name is required";
        if (value.length < 2) return "First name must be at least 2 characters";
        if (!/^[A-Za-z\s]+$/.test(value))
          return "First name should only contain letters";
        return "";

      case "lastName":
        if (!value.trim()) return "Last name is required";
        if (value.length < 2) return "Last name must be at least 2 characters";
        if (!/^[A-Za-z\s]+$/.test(value))
          return "Last name should only contain letters";
        return "";

      case "email":
        if (!value) return "Email is required";
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))
          return "Invalid email format";
        return "";

      case "birthDate":
        if (!value || value === null) return "Date of birth is required";
        const age = calculateAge(new Date(value));
        if (age > 120) return "Invalid date of birth";
        return "";

      case "phoneNumber":
        const trimmedPhone = value.replace(/^0+/, "");
        if (!trimmedPhone) return "Phone number is required";
        if (!/^\d{10}$/.test(trimmedPhone.replace(/[-\s]/g, ""))) {
          return "Phone number must be 10 digits";
        }
        return "";

      case "password":
        if (!value) return "Password is required";
        if (value.length < 8) return "Password must be at least 8 characters";
        if (!/(?=.*[a-z])/.test(value))
          return "Password must include lowercase letters";
        if (!/(?=.*[A-Z])/.test(value))
          return "Password must include uppercase letters";
        if (!/(?=.*\d)/.test(value)) return "Password must include numbers";
        if (!/(?=.*[@$!%*?&])/.test(value))
          return "Password must include special characters";
        return "";

      case "confirmPassword":
        if (!value) return "Please confirm your password";
        if (value !== formData.password) return "Passwords do not match";
        return "";

      default:
        return "";
    }
  };

  // Calculate age from date of birth
  const calculateAge = (birthDate) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(e);

    // Validate field on change
    const error = validateField(name, value);
    setValidationErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  // Handle blur event
  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setValidationErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  // Check if form is valid
  useEffect(() => {
    const hasErrors = Object.values(validationErrors).some(
      (error) => error !== ""
    );
    const isComplete = Object.values(formData).every((value) => value !== "");
    setIsValid && setIsValid(!hasErrors && isComplete);
  }, [validationErrors, formData, setIsValid]);

  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <input
          required
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="First Name"
          className={`w-full px-4 py-2 rounded-lg border ${
            validationErrors.firstName
              ? "border-red-500 focus:ring-red-500"
              : "border-gray-300 focus:ring-primary-500"
          } focus:outline-none focus:ring-2 focus:border-transparent`}
        />
        {validationErrors.firstName && (
          <p className="mt-1 text-sm text-red-500">
            {validationErrors.firstName}
          </p>
        )}
      </div>

      <div>
        <input
          required
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Last Name"
          className={`w-full px-4 py-2 rounded-lg border ${
            validationErrors.lastName
              ? "border-red-500 focus:ring-red-500"
              : "border-gray-300 focus:ring-primary-500"
          } focus:outline-none focus:ring-2 focus:border-transparent`}
        />
        {validationErrors.lastName && (
          <p className="mt-1 text-sm text-red-500">
            {validationErrors.lastName}
          </p>
        )}
      </div>

      <div className="col-span-2">
        <input
          required
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Email"
          className={`w-full px-4 py-2 rounded-lg border ${
            validationErrors.email
              ? "border-red-500 focus:ring-red-500"
              : "border-gray-300 focus:ring-primary-500"
          } focus:outline-none focus:ring-2 focus:border-transparent`}
        />
        {validationErrors.email && (
          <p className="mt-1 text-sm text-red-500">{validationErrors.email}</p>
        )}
      </div>

      <div>
        <input
          type="date"
          name="birthDate"
          value={formData.birthDate}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Date of Birth"
          required
          className={`w-full px-4 py-2 rounded-lg border ${
            validationErrors.birthDate
              ? "border-red-500 focus:ring-red-500"
              : "border-gray-300 focus:ring-primary-500"
          } focus:outline-none focus:ring-2 focus:border-transparent`}
        />
        {validationErrors.birthDate && (
          <p className="mt-1 text-sm text-red-500">
            {validationErrors.birthDate}
          </p>
        )}
      </div>

      <div>
        <input
          required
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Phone Number"
          className={`w-full px-4 py-2 rounded-lg border ${
            validationErrors.phoneNumber
              ? "border-red-500 focus:ring-red-500"
              : "border-gray-300 focus:ring-primary-500"
          } focus:outline-none focus:ring-2 focus:border-transparent`}
        />
        {validationErrors.phoneNumber && (
          <p className="mt-1 text-sm text-red-500">
            {validationErrors.phoneNumber}
          </p>
        )}
      </div>

      <div className="col-span-2">
        <input
          required
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Password"
          className={`w-full px-4 py-2 rounded-lg border ${
            validationErrors.password
              ? "border-red-500 focus:ring-red-500"
              : "border-gray-300 focus:ring-primary-500"
          } focus:outline-none focus:ring-2 focus:border-transparent`}
        />
        {validationErrors.password && (
          <p className="mt-1 text-sm text-red-500">
            {validationErrors.password}
          </p>
        )}
      </div>

      <div className="col-span-2">
        <input
          required
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Confirm Password"
          className={`w-full px-4 py-2 rounded-lg border ${
            validationErrors.confirmPassword
              ? "border-red-500 focus:ring-red-500"
              : "border-gray-300 focus:ring-primary-500"
          } focus:outline-none focus:ring-2 focus:border-transparent`}
        />
        {validationErrors.confirmPassword && (
          <p className="mt-1 text-sm text-red-500">
            {validationErrors.confirmPassword}
          </p>
        )}
      </div>
    </div>
  );
};

export default Validations;
