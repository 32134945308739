import { motion } from "framer-motion";
import React from "react";
import HomePageBanner from "../../gallery/HomePageBanner.jpg";
import HomePageProducts from "../Products/HomePageProducts";
import Differentiator from "../helper/Differentiator";
import Footer from "../helper/Footer";
import Slider from "../helper/Slider";
import { Link } from "react-router-dom";

const HomePage = () => {
  // Smooth fade-up animation
  const fadeInUp = {
    initial: { y: 40, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 1.2, ease: "easeOut" },
  };

  // Organic scale animation
  const naturalScale = {
    initial: { scale: 0.95, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    transition: { duration: 1.4, ease: "easeOut" },
  };

  return (
    <div className="bg-amber-50">
      <Slider />

      {/* Hero Section */}
      <div className="relative w-full mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Hero Content */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, ease: "easeOut" }}
          className="mb-8 sm:mb-12 text-center"
        >
          <motion.h1
            className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold 
              bg-gradient-to-r from-emerald-700 via-green-600 to-emerald-700 bg-clip-text text-transparent 
              font-['Playfair_Display'] tracking-wide leading-relaxed"
            animate={{
              backgroundPosition: ["0% 50%", "100% 50%", "0% 50%"],
            }}
            transition={{
              duration: 8,
              ease: "easeInOut",
              repeat: Infinity,
            }}
          >
            From Nature's Heart to Your Table
          </motion.h1>
        </motion.div>

        {/* Banner Image */}
        <div className="relative h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] rounded-2xl overflow-hidden shadow-lg">
          <motion.img
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1.8, ease: "easeOut" }}
            src={HomePageBanner}
            alt="home page banner"
            className="w-full h-full object-cover"
          />

          {/* Text Overlay */}
          <div className="absolute inset-0 bg-gradient-to-r from-black/20 to-transparent">
            <div className="h-full w-full md:w-1/2 flex flex-col justify-center p-8 sm:p-10 md:p-14 lg:p-16">
              <motion.p
                {...fadeInUp}
                className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-amber-50 mb-6
                  font-['Montserrat'] font-semibold max-w-[90%] sm:max-w-[80%] md:max-w-full
                  drop-shadow-lg"
              >
                Farm-Fresh Goodness
              </motion.p>

              <motion.p
                {...naturalScale}
                className="text-sm sm:text-base md:text-lg lg:text-xl text-amber-50/90 mb-8
                  font-['Roboto'] leading-relaxed max-w-[70%] sm:max-w-[60%] md:max-w-xl
                  tracking-wide drop-shadow-md"
              >
                Discover the pure taste of naturally raised poultry and
                farm-fresh eggs, carefully sourced from local farms that share
                our commitment to quality and sustainability.
              </motion.p>

              <Link to="/products">
                <motion.button
                  {...naturalScale}
                  whileHover={{
                    scale: 1.03,
                    backgroundColor: "rgb(6 95 70)",
                    transition: { duration: 0.2, ease: "easeInOut" },
                  }}
                  whileTap={{ scale: 0.98 }}
                  className="bg-emerald-700 text-amber-50
                  px-8 sm:px-10 py-3 sm:py-4 rounded-full
                  text-sm sm:text-base md:text-lg
                  font-['Montserrat'] font-medium
                  transition-all duration-300
                  shadow-md hover:shadow-xl w-fit
                  border border-emerald-600/20"
                >
                  Browse Our Selection
                </motion.button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Products */}
      <HomePageProducts />

      {/* Differentiator Section */}
      <motion.div
        className="bg-gradient-to-b from-amber-50 to-amber-100/50 px-4 sm:px-8 lg:px-24"
        initial="initial"
        whileInView="animate"
        viewport={{ once: true, margin: "-100px" }}
        variants={fadeInUp}
      >
        <Differentiator />
      </motion.div>

      {/* Decorative Divider */}
      <div className="bg-gradient-to-b from-amber-100/50 to-stone-100 h-20"></div>

      {/* Footer */}
      <motion.div
        className="bg-stone-100 px-4 md:px-12 lg:px-24"
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
        variants={fadeInUp}
      >
        <Footer />
      </motion.div>
    </div>
  );
};

export default HomePage;
