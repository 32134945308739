export const OffersData = [
  {
    id: 1,
    title: "Buy 1 Get 1 Free",
    description: "Buy any 1kg chicken and get 1kg free.",
    image: "buy_1_get_1.jpg",
    promoCode: "B1G1FRESH",
    flag: true,
  },
  {
    id: 2,
    title: "Buy 2 Get 1 Free",
    description: "Buy any 2kg chicken and get 1kg free.",
    image: "buy_2_get_1.jpg",
    promoCode: "DOUBLEDELIGHT",
    flag: true,
  },
  {
    id: 3,
    title: "Buy 1Kg Get 4 Free Eggs",
    description: "Buy any 1kg chicken and get 4 eggs free.",
    image: "buy_1kg_get_4_eggs.jpg",
    promoCode: "EGGSTRA4YOU",
    flag: true,
  },
  {
    id: 4,
    title: "Flat 300",
    description: "Flat 300 off on all orders above 1000.",
    image: "flat_300.jpg",
    promoCode: "SAVE300NOW",
    flag: false,
  },
  {
    id: 5,
    title: "Weekend Special",
    description: "20% off on weekend orders upto 500",
    image: "weekend_special.jpg",
    promoCode: "WEEKENDfeast",
    flag: true,
  },
  {
    id: 6,
    title: "Welcome",
    description: "50% off on first order",
    image: "first_order.jpg",
    promoCode: "WELCOME50",
    flag: true,
  },
  {
    id: 7,
    title: "Bulk Order Discount",
    description: "10% off on orders above 5kg",
    image: "bulk_order.jpg",
    promoCode: "BULKBUY10",
    flag: true,
  },
  {
    id: 8,
    title: "Referral Bonus",
    description: "Get 100 off for each referral",
    image: "referral_bonus.jpg",
    promoCode: "REFER100",
    flag: false,
  },
  {
    id: 9,
    title: "Loyalty Reward",
    description: "Earn 5% back on every purchase",
    image: "loyalty_reward.jpg",
    promoCode: "LOYALTY5",
    flag: false,
  },
  {
    id: 10,
    title: "Holiday Special",
    description: "15% off during the holiday season",
    image: "holiday_special.jpg",
    promoCode: "HOLIDAY15",
    flag: true,
  },
  {
    id: 11,
    title: "Flash Sale",
    description: "30% off for the next 2 hours",
    image: "flash_sale.jpg",
    promoCode: "FLASH30",
    flag: false,
  },
  {
    id: 12,
    title: "Social Media Exclusive",
    description: "10% off for followers on social media",
    image: "social_media_exclusive.jpg",
    promoCode: "SOCIAL10",
    flag: false,
  },
];
