import { motion } from "framer-motion";
import { X, XCircle } from "lucide-react";
import React from "react";

const PaymentFailure = ({ onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm">
      <motion.div
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
        className="bg-white p-8 rounded-xl shadow-2xl text-center max-w-md w-full"
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <X size={24} />
        </button>

        {/* Icon */}
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 300,
            delay: 0.2,
          }}
        >
          <XCircle
            className="mx-auto text-red-500 mb-4"
            size={80}
            strokeWidth={1.5}
          />
        </motion.div>
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Payment Failed
        </h2>
        <p className="text-gray-600 mb-6">
          We couldn't process your payment. Please check your payment details
          and try again or contact support.
        </p>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-red-500 text-white px-6 py-3 rounded-lg hover:bg-red-600 transition-colors"
        >
          Try Again
        </motion.button>
      </motion.div>
    </div>
  );
};

export default PaymentFailure;
