import { motion } from "framer-motion";
import React from "react";
import { FaEgg } from "react-icons/fa";
import { GiChicken, GiEggClutch } from "react-icons/gi";

const Loader = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="fixed inset-0 bg-gradient-to-br from-yellow-50 via-pink-100 to-orange-200 backdrop-blur-md flex items-center justify-center z-50 opacity-70"
    >
      <div className="relative w-96 h-96 flex flex-col items-center justify-center">
        {/* Hen entering animation */}
        <motion.div
          className="absolute bottom-20 left-0"
          initial={{ x: "-20%", y: "20%", opacity: 0 }}
          animate={{ x: "10%", y: "0%", opacity: 1 }}
          transition={{
            duration: 1.5,
            delay: 0.5,
            ease: "easeOut",
          }}
        >
          <GiChicken className="text-yellow-600 text-8xl drop-shadow-md" />
        </motion.div>

        {/* Egg drop animation */}
        <motion.div
          className="absolute top-20 left-1/2 transform -translate-x-1/2"
          initial={{ scale: 0, y: -100, opacity: 0 }}
          animate={{ scale: 1, y: 0, opacity: 1 }}
          transition={{
            duration: 0.1,
            delay: 0.2,
            ease: "backOut",
          }}
        >
          <FaEgg className="text-white text-6xl drop-shadow-lg" />
        </motion.div>

        {/* Egg cracking and chick hatching */}
        <motion.div
          className="absolute top-25 left-1/2 transform -translate-x-1/2"
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1.2, opacity: 1 }}
          transition={{
            delay: 3.5,
            duration: 0.8,
            ease: "easeInOut",
          }}
        >
          <GiEggClutch className="text-yellow-500 text-5xl drop-shadow-md" />
        </motion.div>

        {/* Chick hopping animation */}
        <motion.div
          className="absolute top-72 left-1/2 transform -translate-x-1/2"
          initial={{ y: 20, scale: 1 }}
          animate={{ y: [-10, 0, -10], scale: [1, 1.2, 1] }}
          transition={{
            delay: 4.5,
            duration: 1,
            repeat: Infinity,
            repeatType: "loop",
            ease: "easeInOut",
          }}
        >
          <GiEggClutch className="text-yellow-500 text-5xl drop-shadow-lg" />
        </motion.div>

        {/* Floating particles for visual flair */}
        {[...Array(10)].map((_, index) => (
          <motion.div
            key={index}
            className="absolute w-2 h-2 bg-yellow-400 rounded-full"
            initial={{
              x: Math.random() * 300 - 150,
              y: Math.random() * 300 - 150,
              scale: 0.8,
              opacity: 0.5,
            }}
            animate={{
              y: [Math.random() * 30, Math.random() * -30],
              opacity: [0.5, 1, 0.5],
            }}
            transition={{
              duration: Math.random() * 3 + 2,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default Loader;
