// Utility function to check item categories
const hasCategoryItems = (cartItems, categories) => {
  // console.log(cartItems, "  ana  ", categories);
  const items = cartItems;
  return items?.some((item) =>
    categories?.some((category) =>
      item.category?.toLowerCase().includes(category)
    )
  );
};

// Utility function to get total quantity of specific category items
const getTotalQuantityByCategories = (cartItems, categories) => {
  return cartItems?.reduce((total, item) => {
    if (
      categories.some((category) =>
        item.category?.toLowerCase().includes(category)
      )
    ) {
      return total + item.quantity;
    }
    return total;
  }, 0);
};

export const enableOffer = (promoCode, cartItems) => {
  // setDiscountAtom(promoCode);
  const currentDate = new Date();
  const isWeekend = currentDate.getDay() === 0 || currentDate.getDay() === 6;

  const chickenCategories = ["chicken"];
  // const dairyEggCategories = ["dairy", "dairy & eggs"];

  const hasChickenItems = hasCategoryItems(cartItems, chickenCategories);
  // const hasDairyEggItems = hasCategoryItems(cartItems, dairyEggCategories);
  const chickenItemQuantity = getTotalQuantityByCategories(
    cartItems,
    chickenCategories
  );

  switch (promoCode) {
    case "B1G1FRESH":
    case "EGGSTRA4YOU":
      // Enabled if cart has chicken items and quantity is at least 1
      return hasChickenItems && chickenItemQuantity >= 1;

    case "DOUBLEDELIGHT":
      // Enabled if cart has chicken items and quantity is at least 2
      return hasChickenItems && chickenItemQuantity >= 2;

    // case "SAVE300NOW":
    //   // Enabled if cart has dairy/egg items and value is over 1000
    //   return hasDairyEggItems;
    //  && price >= 1000;

    case "WELCOME50":
      // Always eligible
      return true;

    case "WEEKENDfeast":
      // Enabled during weekends
      return isWeekend;

    case "BULKBUY10":
      // Enabled if chicken items quantity is more than 5
      return hasChickenItems && chickenItemQuantity > 5;

    default:
      return true;
  }
};

export const finalPrice = (price, promoCode) => {
  switch (promoCode) {
    case "SAVE300NOW":
      return Math.max(price - 300, 0);
    case "BULKBUY10":
      return Math.max(price * 0.9, 0);
    case "WELCOME50":
      return Math.max(price * 0.5, 0);
    case "WEEKENDfeast":
      return Math.max(price * 0.8, 0);
    case "HOLIDAY15":
      return Math.max(price * 0.85, 0);
    default:
      return price;
  }
};

export const calculateDiscount = (price, promoCode) => {
  switch (promoCode) {
    case "SAVE300NOW":
      return Math.min(300, price);
    case "BULKBUY10":
      return Math.min(price * 0.1, price);
    case "WELCOME50":
      return Math.min(price * 0.5, price);
    case "WEEKENDfeast":
      return Math.min(price * 0.2, price);
    case "HOLIDAY15":
      return Math.min(price * 0.15, price);
    case "B1G1FRESH":
      return 312; // Fixed value for specific promotion
    case "EGGSTRA4YOU":
      return 50; // Fixed value for specific promotion
    case "DOUBLEDELIGHT":
      return 640; // Fixed value for specific promotion
    default:
      return 0;
  }
};

export const getOfferDescription = (promoCode) => {
  const offerDescriptions = {
    B1G1FRESH: "Buy 1kg chicken, get 1kg free",
    EGGSTRA4YOU: "Buy 1kg chicken, get 4 eggs free",
    DOUBLEDELIGHT: "Buy 2kg chicken, get 1kg free",
    SAVE300NOW: "Flat ₹300 off on dairy/egg orders above ₹1000",
    BULKBUY10: "10% off on chicken items above 5kg",
    WELCOME50: "50% off on first order",
    WEEKENDfeast: "20% off during weekends",
  };

  return offerDescriptions[promoCode] || "Special Offer";
};

export const applicablePromocodes = (cartItems) => {
  const currentDate = new Date();
  const isWeekend = currentDate.getDay() === 0 || currentDate.getDay() === 6;

  const chickenCategories = ["chicken"];
  const hasChickenItems = hasCategoryItems(cartItems, chickenCategories);
  const chickenItemQuantity = getTotalQuantityByCategories(
    cartItems,
    chickenCategories
  );

  const applicableOffers = [];

  if (hasChickenItems) {
    applicableOffers.push("B1G1FRESH");
    if (chickenItemQuantity >= 1) applicableOffers.push("EGGSTRA4YOU");
    if (chickenItemQuantity >= 2) applicableOffers.push("DOUBLEDELIGHT");
    if (chickenItemQuantity > 5) applicableOffers.push("BULKBUY10");
  }

  if (isWeekend) {
    applicableOffers.push("WEEKENDfeast");
  }

  // Always eligible
  applicableOffers.push("WELCOME50");

  return applicableOffers;
};
