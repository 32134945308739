import React, { useState } from "react";
import {
  FaAddressCard,
  FaCreditCard,
  FaEnvelope,
  FaLock,
  FaPhone,
  FaTrash,
  FaUser,
} from "react-icons/fa";
import PasswordSection from "./ChangePassword";
import DeleteAccountSection from "./DeleteAccount";
import EmailSection from "./EmailAddress";
import ExpandableSection from "./ExpandableSection";
import PhoneNumber from "./PhoneNumber";
import AddressesSection from "./SavedAddress";
import SavedCards from "./SavedCards";
import UsernameSection from "./UserName";

const ProfilePage = () => {
  // State for expandable sections
  const [expandedSection, setExpandedSection] = useState(null);

  // User profile data (would typically come from backend/context)
  const [userData, setUserData] = useState({
    username: "JohnDoe123",
    email: "johndoe@example.com",
    phoneNumber: "+1 (555) 123-4567",
    addresses: [
      {
        id: 1,
        street: "123 Main St",
        city: "Anytown",
        state: "CA",
        zip: "12345",
      },
      {
        id: 2,
        street: "456 Oak Ave",
        city: "Somewhere",
        state: "NY",
        zip: "67890",
      },
    ],
    cards: [
      {
        id: 1,
        name: "Nick",
        cardNumber: "**** **** **** 1234",
        type: "Visa",
        expiryDate: "11/25",
      },
      {
        id: 2,
        name: "John",
        cardNumber: "**** **** **** 5678",
        type: "Mastercard",
        expiryDate: "11/25",
      },
    ],
  });

  // Toggle section expansion
  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="container max-w-2xl mx-auto py-8 px-4">
      {/* <h1 className="text-3xl font-bold mb-6 text-gray-900">My Profile</h1> */}

      <ExpandableSection
        title="Username"
        icon={FaUser}
        section="username"
        expandedSection={expandedSection}
        toggleSection={toggleSection}
      >
        <UsernameSection
          userData={userData}
          setUserData={setUserData}
          toggleSection={toggleSection}
        />
      </ExpandableSection>

      <ExpandableSection
        title="Email Address"
        icon={FaEnvelope}
        section="email"
        expandedSection={expandedSection}
        toggleSection={toggleSection}
      >
        <EmailSection
          userData={userData}
          setUserData={setUserData}
          toggleSection={toggleSection}
        />
      </ExpandableSection>

      <ExpandableSection
        title="Phone Number"
        icon={FaPhone}
        section="phone"
        expandedSection={expandedSection}
        toggleSection={toggleSection}
      >
        <PhoneNumber
          userData={userData}
          setUserData={setUserData}
          toggleSection={toggleSection}
        />
      </ExpandableSection>

      <ExpandableSection
        title="Saved Addresses"
        icon={FaAddressCard}
        section="addresses"
        expandedSection={expandedSection}
        toggleSection={toggleSection}
      >
        <AddressesSection
          userData={userData}
          setUserData={setUserData}
          toggleSection={toggleSection}
        />
      </ExpandableSection>

      <ExpandableSection
        title="Saved Cards"
        icon={FaCreditCard}
        section="cards"
        expandedSection={expandedSection}
        toggleSection={toggleSection}
      >
        <SavedCards
          userData={userData}
          setUserData={setUserData}
          toggleSection={toggleSection}
        />
      </ExpandableSection>

      <ExpandableSection
        title="Change Password"
        icon={FaLock}
        section="password"
        expandedSection={expandedSection}
        toggleSection={toggleSection}
      >
        <PasswordSection toggleSection={toggleSection} />
      </ExpandableSection>

      <ExpandableSection
        title="Delete Account"
        icon={FaTrash}
        section="delete"
        expandedSection={expandedSection}
        toggleSection={toggleSection}
      >
        <DeleteAccountSection />
      </ExpandableSection>
    </div>
  );
};

export default ProfilePage;
