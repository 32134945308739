import React, { useState } from "react";

const DeleteAccountSection = () => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleAccountDeletion = () => {
    // Implement account deletion logic
    console.log("Account deleted");
  };

  return (
    <div className="p-4 border-t space-y-4 bg-red-50">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-lg font-semibold text-red-600">Delete Account</h3>
          <p className="text-sm text-gray-600">
            This action cannot be undone. All your data will be permanently
            removed.
          </p>
        </div>
        <input
          type="checkbox"
          checked={confirmDelete}
          onChange={() => setConfirmDelete(!confirmDelete)}
          className="h-5 w-5 text-red-600 focus:ring-red-500 border-gray-300 rounded"
        />
      </div>
      <button
        onClick={handleAccountDeletion}
        disabled={!confirmDelete}
        className={`w-full py-3 rounded-lg transition-colors ${
          confirmDelete
            ? "bg-red-600 text-white hover:bg-red-700"
            : "bg-gray-200 text-gray-400 cursor-not-allowed"
        }`}
      >
        Permanently Delete My Account
      </button>
    </div>
  );
};

export default DeleteAccountSection;
