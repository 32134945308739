import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Account from "./components/Account/Account";
import HomePage from "./components/HomePage/HomePage";
import Navbar from "./components/NavBar/Navbar";
import Products from "./components/Products/Products";
import Loader from "./components/ResuableComponents/Loader";

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    // Skip loader for initial load
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }

    // Show loader during route transitions
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust duration as needed

    return () => clearTimeout(timer);
  }, [location.pathname, initialLoad]);

  return (
    <>
      {loading && <Loader />}
      {!loading && <Navbar />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<Products />} />
        <Route path="/account/*" element={<Account />} />
      </Routes>
    </>
  );
}

export default App;
