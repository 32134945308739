import { AnimatePresence, motion } from "framer-motion";
import {
  ArrowRight,
  CheckCircle,
  Sparkles,
  Tag,
  Wand2,
  X,
  XCircle,
} from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { cartListAtom, discountAtom } from "../recoil/atoms/CartStateAtom";
import {
  applicablePromocodes,
  calculateDiscount,
} from "../utils/validateOfferUtil";
import DiscountModal from "./DiscountModal";

const Discount = ({ summaryData }) => {
  const [promoCodeInput, setPromoCodeInput] = useState("");
  const [promoMessage, setPromoMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [discountModalOpen, setDiscountModalOpen] = useState(false);
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [discountState, setDiscountState] = useRecoilState(discountAtom);
  const cartListItems = useRecoilValue(cartListAtom);

  const handlePromoCode = (e) => {
    setDiscountState((prevState) => ({
      ...prevState,
      offerCode: "",
    }));
    setPromoCodeInput(e.target.value.toUpperCase());
    if (isPromoApplied) {
      setIsPromoApplied(false);
      setPromoMessage("");
    }
  };

  const clearPromoInput = useCallback(() => {
    setPromoCodeInput("");
    setPromoMessage("");
    setDiscountState((prevState) => ({
      ...prevState,
      offerCode: "",
    }));
    setIsPromoApplied(false);
  }, [setDiscountState]);

  const promoCheck = async () => {
    setIsSubmitting(true);
    const allApplicableOffers = applicablePromocodes(cartListItems);
    const isValid = allApplicableOffers.includes(promoCodeInput.toUpperCase());

    if (isValid) {
      const discountAmount = calculateDiscount(
        promoCodeInput,
        summaryData.totalAmount
      );
      setDiscountState((prevState) => ({
        ...prevState,
        offerCode: promoCodeInput,
        discount: discountAmount,
      }));
      setPromoMessage("Successfully applied promo code!");
      setIsPromoApplied(true);
    } else {
      setPromoMessage("Invalid promo code");
      setDiscountState((prevState) => ({
        ...prevState,
        offerCode: "",
      }));
      setIsPromoApplied(false);
    }
    setIsSubmitting(false);
  };

  const handleSelectOffer = (offerDetails) => {
    const { promoCode, title } = offerDetails;

    setPromoCodeInput(promoCode);

    const validatePromoCode = () => {
      const allApplicableOffers = applicablePromocodes(cartListItems);
      const isValid = allApplicableOffers.includes(promoCode.toUpperCase());

      if (isValid) {
        const discountAmount = calculateDiscount(
          promoCode,
          summaryData.totalAmount
        );

        setDiscountState((prevState) => ({
          ...prevState,
          offerCode: promoCode,
          discount: discountAmount,
        }));

        setPromoMessage(`Successfully applied ${title}!`);
        setIsPromoApplied(true);
      } else {
        setPromoMessage("Invalid promo code");
        setDiscountState((prevState) => ({
          ...prevState,
          offerCode: "",
          discount: 0,
        }));
        setIsPromoApplied(false);
      }
    };

    setTimeout(validatePromoCode, 100);
  };

  // Reset promo code and message if the cart items change
  useEffect(() => {
    const allApplicableOffers = applicablePromocodes(cartListItems);
    const isPromoCodeValid = allApplicableOffers.includes(
      discountState.offerCode
    );

    if (!isPromoCodeValid) {
      clearPromoInput();
    }
  }, [
    cartListItems,
    discountState.offerCode,
    setDiscountState,
    clearPromoInput,
  ]);

  return (
    <div className="space-y-3">
      {/* Promo Code Input Section */}
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3 relative"
      >
        <div className="relative flex-1 group">
          <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
            <Tag className="h-4 w-4 text-gray-400 group-focus-within:text-indigo-500 transition-colors" />
          </div>
          <input
            type="text"
            placeholder="Enter promo code"
            value={promoCodeInput || discountState.offerCode}
            className={`w-full pl-10 pr-10 py-2 sm:py-3 text-sm text-gray-700 
              placeholder-gray-400 border border-gray-200 rounded-lg 
              focus:outline-none focus:border-indigo-400 focus:ring-1 
              focus:ring-indigo-400 transition-all duration-200 uppercase
              ${isPromoApplied ? "bg-green-50 border-green-200" : ""}`}
            onChange={handlePromoCode}
          />
          {promoCodeInput && (
            <button
              className="absolute inset-y-0 right-3 flex items-center justify-center text-gray-400 hover:text-gray-600"
              onClick={clearPromoInput}
            >
              <X className="h-4 w-4" />
            </button>
          )}
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className={`w-full sm:w-auto px-4 sm:px-6 py-2 sm:py-3 
            ${
              isSubmitting
                ? "bg-gray-400"
                : isPromoApplied
                ? "bg-green-600"
                : "bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-800"
            }
            text-white rounded-lg font-medium text-sm 
            transition-all duration-200 hover:shadow-md 
            flex items-center justify-center space-x-2 group 
            disabled:cursor-not-allowed disabled:opacity-50`}
          onClick={promoCheck}
          disabled={isSubmitting || !promoCodeInput || isPromoApplied}
        >
          <span className="truncate">
            {isSubmitting
              ? "Applying..."
              : isPromoApplied
              ? "Applied"
              : "Apply"}
          </span>
          {isPromoApplied ? (
            <CheckCircle className="h-4 w-4 animate-bounce" />
          ) : (
            <Sparkles
              className={`h-4 w-4 transition-all duration-300 
              ${
                isSubmitting
                  ? "animate-pulse"
                  : "group-hover:rotate-12 group-hover:scale-110"
              }`}
            />
          )}
        </motion.button>
      </motion.div>
      <AnimatePresence>
        {promoMessage && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className={`flex items-center justify-center space-x-2 text-sm font-medium 
              ${
                promoMessage.includes("Successfully")
                  ? "text-green-600"
                  : "text-red-500"
              }`}
          >
            {promoMessage.includes("Successfully") ? (
              <CheckCircle className="h-5 w-5 animate-ping-once" />
            ) : (
              <XCircle className="h-5 w-5 animate-shake" />
            )}
            <span>{promoMessage}</span>
          </motion.div>
        )}
      </AnimatePresence>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="group w-full flex items-center justify-center 
          text-indigo-600 hover:text-indigo-700 
          space-x-2 py-2 border-t border-gray-100 pt-3 
          transition-all duration-300"
        onClick={() => setDiscountModalOpen(true)}
      >
        <Wand2 className="h-5 w-5 mr-2 text-indigo-500 group-hover:rotate-45 transition-transform" />
        <span className="font-semibold text-sm">
          Discover More Exciting Offers
        </span>
        <ArrowRight className="h-4 w-4 group-hover:translate-x-1 transition-transform" />
      </motion.button>
      <DiscountModal
        isOpen={discountModalOpen}
        onClose={() => setDiscountModalOpen(false)}
        onSelectOffer={handleSelectOffer}
        summaryData={summaryData}
      />
    </div>
  );
};

export default Discount;
