import React, { useState } from "react";

const PhoneNumber = ({ userData, setUserData, toggleSection }) => {
  const [newPhoneNumber, setNewPhoneNumber] = useState(userData.phoneNumber);

  const handlePhoneNumberUpdate = () => {
    // Implement phoneNumber update logic
    setUserData((prev) => ({ ...prev, phoneNumber: newPhoneNumber }));
    toggleSection(null);
  };

  return (
    <div className="p-4 border-t">
      <div className="flex items-center gap-3 mb-4">
        <input
          type="phoneNumber"
          value={newPhoneNumber}
          onChange={(e) => setNewPhoneNumber(e.target.value)}
          className="flex-1 p-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 outline-none"
        />
      </div>
      <div className="flex justify-end space-x-2">
        <button
          onClick={() => toggleSection(null)}
          className="px-3 py-2 text-gray-600 hover:bg-gray-50 rounded-lg"
        >
          Cancel
        </button>
        <button
          onClick={handlePhoneNumberUpdate}
          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default PhoneNumber;
