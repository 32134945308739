import React, { useState } from "react";

const PasswordSection = ({ toggleSection }) => {
  const [passwords, setPasswords] = useState({
    current: "",
    new: "",
    confirm: "",
  });

  const handlePasswordChange = () => {
    // Implement password change logic
    if (passwords.new === passwords.confirm) {
      // Perform password change
      toggleSection(null);
    }
  };

  return (
    <div className="p-4 border-t space-y-4">
      <input
        type="password"
        placeholder="Current Password"
        value={passwords.current}
        onChange={(e) =>
          setPasswords((prev) => ({ ...prev, current: e.target.value }))
        }
        className="w-full p-2 border rounded-lg"
      />
      <input
        type="password"
        placeholder="New Password"
        value={passwords.new}
        onChange={(e) =>
          setPasswords((prev) => ({ ...prev, new: e.target.value }))
        }
        className="w-full p-2 border rounded-lg"
      />
      <input
        type="password"
        placeholder="Confirm New Password"
        value={passwords.confirm}
        onChange={(e) =>
          setPasswords((prev) => ({ ...prev, confirm: e.target.value }))
        }
        className="w-full p-2 border rounded-lg"
      />
      <div className="flex justify-end space-x-2">
        <button
          onClick={() => toggleSection(null)}
          className="px-3 py-2 text-gray-600 hover:bg-gray-50 rounded-lg"
        >
          Cancel
        </button>
        <button
          onClick={handlePasswordChange}
          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          Change Password
        </button>
      </div>
    </div>
  );
};

export default PasswordSection;
