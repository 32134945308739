import { motion } from "framer-motion";
import React from "react";
import { useRecoilValue } from "recoil";
import Discount from "../OffersModal/Discount";
import { discountAtom } from "../recoil/atoms/CartStateAtom";
import { calculateDiscount, finalPrice } from "../utils/validateOfferUtil";

const Summary = ({ summaryData }) => {
  const discountState = useRecoilValue(discountAtom);
  const currentPromoCode = discountState.offerCode;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="bg-white sm:bg-gray-50 rounded-lg p-4 sm:p-6 shadow-sm"
    >
      <Discount summaryData={summaryData} />

      <h2 className="text-lg font-semibold mb-4 text-gray-800">
        Order Summary
      </h2>

      <div className="space-y-3 text-gray-600 text-sm sm:text-base">
        <div className="flex justify-between items-center">
          <p className="text-gray-500">Total MRP</p>
          <p className="font-medium">₹{summaryData.totalMRP.toFixed(2)}</p>
        </div>

        <div className="flex justify-between items-center">
          <p className="text-gray-500">Shipping</p>
          <p className="font-medium text-green-600">
            {summaryData.shippingFee === 0
              ? "FREE"
              : `₹${summaryData.shippingFee.toFixed(2)}`}
          </p>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex items-center gap-1">
            <p className="text-gray-500">GST & CGST</p>
            <span className="text-xs text-gray-400">(5%)</span>
          </div>
          <p className="font-medium">₹{summaryData.tax}</p>
        </div>

        {currentPromoCode && (
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-1">
              <p className="text-gray-500">Promo Code</p>
              <span className="text-xs text-gray-400">
                ({currentPromoCode})
              </span>
            </div>
            <p className="font-medium text-green-600">
              - ₹
              {calculateDiscount(
                summaryData.totalMRP,
                currentPromoCode
              ).toFixed(2)}
            </p>
          </div>
        )}

        <div className="border-t border-gray-200 my-2"></div>

        <div className="flex justify-between items-center pt-2">
          <p className="font-semibold text-gray-800">Total Amount</p>
          <div className="text-right">
            <p className="font-bold text-lg text-gray-800">
              {currentPromoCode ? (
                <span>
                  ₹{finalPrice(summaryData.totalAmount, currentPromoCode)}
                </span>
              ) : (
                <span>₹{summaryData.totalAmount}</span>
              )}
            </p>
            <p className="text-xs text-green-600 font-medium">
              {summaryData.shippingFee === 0 && "Free Delivery"}
            </p>
          </div>
        </div>
      </div>

      {summaryData.shippingFee === 0 && (
        <div className="mt-4 bg-green-50 p-2 rounded-md">
          <p className="text-xs sm:text-sm text-green-700 font-medium text-center">
            🎉 You've saved ₹40 on shipping!
          </p>
        </div>
      )}
    </motion.div>
  );
};

export default Summary;
